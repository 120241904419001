.SmoothWrapper {
  width: 100%;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
}

.SmoothWrapper--sticky {
  transition: width 0.5s;
  width: 102%;
}

.SmoothWrapper--helperWrapper {
  width: 98%;
}

.WrapperSm {
  top: 5.625rem;
  z-index: 99;
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  left: 0;
  right: 0;
}
