/*
this color is for logo only, so not added to tw config
*/

.Logo {
  display: flex;
  align-items: center;
  line-height: 1;
  font-family: Noto Sans, sans-serif;
  font-size: 1.25rem;
  text-decoration: none;
}

.Logo--white {
  color: #ffffff;
}

.Logo--black {
  color: #000000;
}

.Logo--purple {
  color: #871e8c;
}

.Logo svg g:nth-child(3) {
  fill: currentColor;
}

.Logo--sm svg:first-of-type {
  width: 140px;
}

.Logo--md svg:first-of-type {
  width: 240px;
}

.Logo--lg svg:first-of-type {
  width: 352px;
}

.Logo__svgIcon,
.Logo__svgIcon.Icon {
  width: 1rem;
  height: 1rem;
}

.Logo__svgIcon--white,
.Logo__svgIcon.Icon--white {
  color: #ffffff;
}

.Logo__svgIcon--purple,
.Logo__svgIcon.Icon--purple {
  color: #871e8c;
}

.Logo__svgIcon--black,
.Logo__svgIcon.Icon--black {
  color: #000000;
}

@media (min-width: 1280px) {
  .Logo__svgIcon,
  .Logo__svgIcon.Icon {
    display: none;
  }
}

.Logo__svgTitle {
  display: block;
}

.Logo__svgTitle--responsive {
  display: none;
}

@media (min-width: 1280px) {
  .Logo__svgTitle {
    display: block;
    flex-shrink: 0;
    fill: currentColor;
    margin-bottom: -0.3rem;
  }
}

.Logo__svgTitle--sm {
  width: 140px;
}

.Logo__svgTitle--md {
  width: 240px;
}

.Logo__svgTitle--lg {
  width: 352px;
}

.Logo__customTitle {
  display: none;
}

@media (min-width: 768px) {
  .Logo__customTitle {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    font-weight: 300;
    position: relative;
    top: 1px;
  }
}

.Logo__customTitle::before {
  display: inline-block;
  width: 1px;
  height: 1.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-right: 1rem;
  content: '';
}

.Logo__customTitle--sm {
  font-size: 0.75rem;
}

.Logo__customTitle--md {
  font-size: 1.5rem;
}

.Logo__customTitle--lg {
  font-size: 2rem;
}

.Logo__customTitle--white {
  color: #ffffff;
}

.Logo__customTitle--white::before {
  background-color: #ffffff;
}

.Logo__customTitle--purple {
  color: #871e8c;
}

.Logo__customTitle--purple::before {
  background-color: #871e8c;
}

.Logo__customTitle--black {
  color: #000000;
}

.Logo__customTitle--black::before {
  background-color: #000000;
}

.Header .Logo {
  flex-shrink: 0;
}
