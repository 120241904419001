.PromotionalBannerContent__wrapper {
  padding: 1.5rem 2.25rem 3rem 2.25rem;
  flex-grow: 1;
}

.PromotionalBannerContent__headerTruncate {
  margin-bottom: 2rem;
}

.PromotionalBannerContent__optionsTruncate {
  margin-bottom: 2rem;
}

.PromotionalBannerContent__heading {
  font-size: 2rem;
  letter-spacing: 0;
  font-weight: 300;
  line-height: 2.625rem;
}

.PromotionalBannerContent__main {
  display: flex;
  flex-direction: column;
}

.PromotionalBannerContent__button_wrapper {
  width: 100%;
  margin-top: auto;
  margin-left: auto;
}

.PromotionalBannerContent__button_wrapper .Button {
  margin: 0;
  width: 100%;
}

ul .PromotionalBannerContent__option {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.375;
  color: #3b474d;
}

ul .PromotionalBannerContent__option::before {
  margin-left: 0;
}

@media (min-width: 768px) {
  .PromotionalBannerContent__wrapper {
    width: 50%;
    padding: 1rem 2rem 2rem 1.25rem;
  }

  .PromotionalBannerContent__headerTruncate {
    margin-bottom: 0.5rem;
  }

  .PromotionalBannerContent__optionsTruncate {
    margin-bottom: 0.5rem;
  }

  .PromotionalBannerContent__heading {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .PromotionalBannerContent__button_wrapper {
    width: auto;
  }
}

@media (min-width: 1024px) {
  .PromotionalBannerContent__wrapper {
    padding: 1.5rem 2.25rem 2.25rem 1.5rem;
  }

  .PromotionalBannerContent__optionsTruncate {
    margin-bottom: 0;
  }

  .PromotionalBannerContent__headerTruncate {
    margin-bottom: 1.5rem;
  }

  .PromotionalBannerContent__heading {
    font-size: 2rem;
    line-height: 2.625rem;
  }

  .PromotionalBannerContent__button_wrapper {
    margin-top: 2rem;
    width: auto;
    margin-left: 2.5rem;
    white-space: nowrap;
  }

  .PromotionalBannerContent__main {
    flex-direction: row;
    justify-content: space-between;
  }

  .PromotionalBannerContent__main .List {
    margin-bottom: 0;
  }

  .PromotionalBannerContent__list {
    flex-grow: 1;
  }
}
