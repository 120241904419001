.Wizard__nav {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.Wizard__navItem {
  flex: 1 1 0%;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-align: center;
  line-height: 1;
}

/**
 * Stage title
 */

.Wizard__navTitle {
  font-weight: 300;
  color: #596b74;
}

.Wizard__navTitle--current {
  font-weight: 600;
  color: #6d98ed;
}

/**
 * Line / connector
 * Solid for completed stages, dashed for future stages
 */

.Wizard__navIndicators {
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .Wizard__navIndicators {
    margin-top: 0.75rem;
  }
}

.Wizard__navIndicators::before,
.Wizard__navIndicators::after {
  z-index: 10;
  display: block;
  flex-grow: 1;
  content: '';
  border-top-width: 1px;
  border-style: dashed;
  border-color: #99abb4;
}

.Wizard__navIndicators--current::before,
.Wizard__navIndicators--complete::before,
.Wizard__navIndicators--complete:not(.Wizard__navIndicators--furthest)::after {
  border-style: solid;
  border-color: #6d98ed;
}

.Wizard__navItem:first-child .Wizard__navIndicators::before,
.Wizard__navItem:last-child .Wizard__navIndicators::after {
  border-style: none;
}

/**
 * Icon / circle indicator
 * Dot for current stage,  checkmark for completed stages, white/blank for future stages
 */

.Wizard__navIcon {
  z-index: 20;
  flex-shrink: 0;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  border-width: 1px;
  border-style: solid;
  border-color: #6d98ed;
  background-color: #dae5f9;
  border-radius: 9999px;
}

.Wizard__navIcon::before {
  content: '';
  background-color: #6d98ed;
  width: 0.75rem;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Wizard__navIcon--complete::before {
  width: 0.75rem;
  height: 0.25rem;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: #6d98ed;
  background-color: transparent;
  transform: translate(-50%, -50%) rotate(-45deg);
  margin-top: -1px;
}

.Wizard__navIcon--current::before {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 9999px;
  background-color: #6d98ed;
  border-width: 0;
  margin-top: 0;
}

.Wizard__navIcon--toComplete {
  border-color: #99abb4;
  background-color: #ffffff;
}

.Wizard__navIcon--toComplete::before {
  content: none;
}

/**
 * Hover / interactive states
 */

.Wizard__navItem:disabled {
  cursor: default;
}

.Wizard__navItem:not(:disabled):hover .Wizard__navIcon,
.Wizard__navItem:not(:disabled).focus-visible .Wizard__navIcon {
  border-color: #936fe1;
  background-color: #936fe1;
}

.Wizard__navItem:not(:disabled):active .Wizard__navIcon {
  border-color: #7141d8;
  background-color: #7141d8;
}

.Wizard__navItem:not(:disabled):hover .Wizard__navIcon::before,
.Wizard__navItem:not(:disabled).focus-visible .Wizard__navIcon::before,
.Wizard__navItem:not(:disabled):active .Wizard__navIcon::before {
  background-color: #ffffff;
}

.Wizard__navItem:not(:disabled):hover .Wizard__navIcon--complete:not(.Wizard__navIcon--current)::before,
.Wizard__navItem:not(:disabled).focus-visible .Wizard__navIcon--complete:not(.Wizard__navIcon--current)::before,
.Wizard__navItem:not(:disabled):active .Wizard__navIcon--complete:not(.Wizard__navIcon--current)::before {
  background-color: transparent;
  border-color: #ffffff;
}

/**
 * Responsive behavior
 * Individual stage titles should be visibly hidden (but still screen reader accessible)
 * The current stage title should be absolutely horizontally centered across the entire nav
 */

.Wizard__navTitle:not(.Wizard__navTitle--current) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

@media (min-width: 768px) {
  .Wizard__navTitle:not(.Wizard__navTitle--current) {
    position: unset;
    width: unset;
    height: unset;
    margin: unset;
    padding: unset;
    border: unset;
    overflow: unset;
    clip: unset;
  }
}

.Wizard__navTitle--current {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .Wizard__navTitle--current {
    position: static;
  }
}

.Wizard__nav {
  padding-top: 1.5rem;
}

@media (min-width: 768px) {
  .Wizard__nav {
    padding-top: 0;
  }
}
