.Drawer {
  transition-property: transform;
  transition-timing-function: ease-out;
  transition-duration: 200ms;
  will-change: transform;
  transform: translateY(-100%);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
}

@media (min-width: 768px) {
  .Drawer {
    width: 36rem;
  }

  .Drawer--right {
    left: auto;
    transform: translateX(100%);
  }

  .Drawer--left {
    right: auto;
    transform: translateX(-100%);
  }
}

.Drawer__close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1rem;
  line-height: 1;
  color: #596b74;
}

@media (min-width: 768px) {
  .Drawer__close {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 1px;
    margin-right: 1px;
    border-top-right-radius: 0.5rem;
  }
}

.Drawer__close:hover,
.Drawer__close.focus-visible {
  color: #bbc7cd;
}

.DrawerModal__Icon {
  width: 3rem;
  height: 3rem;
  margin-bottom: 0.75rem;
  border-radius: 9999px;
  padding: 0;
  color: #99abb4;
}

.DrawerModal .ModalFooter {
  padding-top: 0;
}

.Blanket--leave.Blanket--leaveActive .Drawer {
  transform: translateY(-100%);
}

@media (min-width: 768px) {
  .Blanket--leave.Blanket--leaveActive .Drawer--right {
    transform: translateX(100%);
  }

  .Blanket--leave.Blanket--leaveActive .Drawer--left {
    transform: translateX(-100%);
  }
}

.Blanket--isMounted .Drawer,
.Blanket--enter.Blanket--enterActive .Drawer {
  transform: none;
}
