.ModalFooter {
  width: 100%;
  margin-top: auto;
  text-align: right;
  flex-shrink: 0;
}

.ModalFooter--hasChildren {
  padding-top: 1rem;
}
