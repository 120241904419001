.SelectWrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  /**
 * Select & Label position variants
 */
}

.SelectWrapper--row {
  flex-direction: row;
}

.SelectWrapper--row .Select__label {
  margin-right: 0.5rem;
  align-self: center;
}

.SelectWrapper--col {
  flex-direction: column;
  align-items: flex-start;
}

.SelectWrapper--rowReverse .Select__label {
  order: 1;
  margin-left: 0.5rem;
  margin-bottom: 0;
  align-self: center;
}

.SelectWrapper--colReverse {
  flex-direction: column-reverse;
  align-items: flex-start;
}

.SelectWrapper--colReverse .Select__label {
  margin-top: 0.25rem;
}

.SelectWrapper--small {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.Select {
  display: inline-block;
  height: 2rem;
  margin-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 2rem;
  line-height: 1.25;
  padding-top: 1px;
  font-family: Noto Sans, sans-serif;
  font-size: 1rem;
  border-width: 1px;
  border-color: #788f9c;
  border-radius: 0.25rem;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 24 12' fill='%237141d8'><polygon points='0,0 24,0 12,14'/></svg>");
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: right 0.55rem center;
  color: #596b74;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.Select::-ms-expand {
  display: none;
}

/**
 * Size variants
 */

.Select--small {
  font-size: 0.75rem;
  height: 1.5rem;
  padding-right: 1.5rem;
}

.Select--large {
  height: 3rem;
  padding-top: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 3rem;
  background-size: 1rem;
}

/**
 * Disabled variant
 */

.Select--disabled {
  color: #99abb4;
  border-color: #dde2e6;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 24 12' fill='%23dde2e6'><polygon points='0,0 24,0 12,14'/></svg>");
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: right 0.55rem center;
  background-color: #f4f4f4;
  cursor: not-allowed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/**
 * Use Ellipsis for overflow
 */

.Select--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select__helperWrapper {
  display: flex;
  align-items: flex-start;
}

.Select__helperWrapper:not(:empty) {
  margin-top: 0.25rem;
}

.Select__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

/**
 * Validation & Errors
 */

.Select__icon--valid {
  color: #8bca2f;
}

.Select__icon--error {
  color: #b21111;
}
