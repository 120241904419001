.Table__TableData,
.Table__TableHeader,
.Table__TableFooter {
  padding-left: 2rem;
  padding-right: 2rem;
}

.Table__TableData {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-right: 2rem;
  text-align: right;
}

.Table__TableFooter .Table__TableData {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.Table__TableHeader,
.Table__TableFooter {
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
  line-height: 1.5;
  color: #596b74;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
}

.Table__TableFooter {
  font-weight: 600;
}

/**
 * Special two-line styling for first column
 */

.Table__TableHeader[scope='row'],
.Table__TableHeader:first-child {
  text-align: left;
}

.Table__TableHeader[scope='row'] + *,
.Table__TableHeader:first-child + *,
.Table__TableHeader[scope='row'] ~ .Table__TableHeader--nextVisibleColumn,
.Table__TableHeader:first-child ~ .Table__TableHeader--nextVisibleColumn,
.Table__TableHeader[scope='row'] ~ .Table__TableData--nextVisibleColumn,
.Table__TableHeader:first-child ~ .Table__TableData--nextVisibleColumn {
  border-left-width: 0;
}

.Table__TableFooter[scope='row'],
.Table__TableFooter:first-child {
  text-align: left;
  vertical-align: middle;
}

.Table__TableFooter[scope='row'] + *,
.Table__TableFooter:first-child + *,
.Table__TableFooter[scope='row'] ~ .Table__TableFooter--nextVisibleColumn,
.Table__TableFooter:first-child ~ .Table__TableFooter--nextVisibleColumn,
.Table__TableFooter[scope='row'] ~ .Table__TableData--nextVisibleColumn,
.Table__TableFooter:first-child ~ .Table__TableData--nextVisibleColumn {
  border-left-width: 0;
}

.Table__TableHead .Table__TableHeader:first-child::after {
  bottom: -2px;
}

.Table__TableFoot .Table__TableFooter:first-child::before {
  top: -2px;
}

/**
 * Cell typography helpers
 */

.Table__TableData--center {
  text-align: center;
}

.Table__TableData--left {
  text-align: left;
}

.Table__TableData--right {
  text-align: right;
}

.Table__TableData--bottom {
  vertical-align: bottom;
}

.Table__TableData--middle {
  vertical-align: middle;
}

.Table__TableData--top {
  vertical-align: top;
}

.Table__TableData--bold {
  font-weight: 700;
}

.Table__TableData--red {
  color: #de1515;
}

.Table__TableData--green {
  color: #8bca2f;
}
