.PresetDateRangePicker_panel {
  padding: 0 22px 11px
}

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer
}

.PresetDateRangePicker_button:active {
  outline: 0
}

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699
}

.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff
}

.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
  direction: rtl
}

.SingleDatePickerInput__disabled {
  background-color: #f2f2f2
}

.SingleDatePickerInput__block {
  display: block
}

.SingleDatePickerInput__showClearDate {
  padding-right: 30px
}

.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
  padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
  visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
  height: 9px
}

.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}

.SingleDatePicker {
  position: relative;
  display: inline-block
}

.SingleDatePicker__block {
  display: block
}

.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}

.SingleDatePicker_picker__rtl {
  direction: rtl
}

.SingleDatePicker_picker__directionLeft {
  left: 0
}

.SingleDatePicker_picker__directionRight {
  right: 0
}

.SingleDatePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff
}

.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}

.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0
}

.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px
}

.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left
}

.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0
}

.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
  outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a
}

.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center
}

.CalendarDay:active {
  outline: 0
}

.CalendarDay__defaultCursor {
  cursor: default
}

.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff
}

.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit
}

.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit
}

.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848
}

.CalendarDay__outside:hover {
  border: 0
}

.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd
}

.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848
}

.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87
}

.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7
}

.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0
}

.CalendarMonth_verticalSpacing {
  border-collapse: separate
}

.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px
}

.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0
}

.CalendarMonthGrid__animating {
  z-index: 1
}

.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px
}

.CalendarMonthGrid__vertical {
  margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%
}

.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none
}

.CalendarMonthGrid_month__hidden {
  visibility: hidden
}

.DayPickerNavigation {
  position: relative;
  z-index: 2
}

.DayPickerNavigation__horizontal {
  height: 0
}

.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
  position: relative
}

.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0
}

.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
  background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
  background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: .78;
  border-radius: 3px;
  padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px
}

.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%
}

.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block
}

.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848
}

.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2
}

.DayPicker {
  background: #fff;
  position: relative;
  text-align: left
}

.DayPicker__horizontal {
  background: #fff
}

.DayPicker__verticalScrollable {
  height: 100%
}

.DayPicker__hidden {
  visibility: hidden
}

.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
  border-radius: 3px
}

.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%
}

.DayPicker_portal__vertical {
  position: initial
}

.DayPicker_focusRegion {
  outline: 0
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top
}

.DayPicker_weekHeaders {
  position: relative
}

.DayPicker_weekHeaders__horizontal {
  margin-left: 9px
}

.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left
}

.DayPicker_weekHeader__vertical {
  left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center
}

.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px
}

.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center
}

.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
  transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
  width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll
}

.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle
}

.DateInput__small {
  width: 97px
}

.DateInput__block {
  width: 100%
}

.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb
}

.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0
}

.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .2px;
  padding: 7px 7px 5px
}

.DateInput_input__regular {
  font-weight: auto
}

.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0
}

.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic
}

.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2
}

.DateInput_fangShape {
  fill: #fff
}

.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent
}

.DateRangePickerInput {
  background-color: #fff;
  display: inline-block
}

.DateRangePickerInput__disabled {
  background: #f2f2f2
}

.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
  direction: rtl
}

.DateRangePickerInput__block {
  display: block
}

.DateRangePickerInput__showClearDates {
  padding-right: 30px
}

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px
}

.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
  padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
  visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
  height: 9px
}

.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}

.DateRangePicker {
  position: relative;
  display: inline-block
}

.DateRangePicker__block {
  display: block
}

.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}

.DateRangePicker_picker__rtl {
  direction: rtl
}

.DateRangePicker_picker__directionLeft {
  left: 0
}

.DateRangePicker_picker__directionRight {
  right: 0
}

.DateRangePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff
}

.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}

.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}

.DatePicker,
.DayPicker_portal__horizontal {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: Noto Sans, sans-serif;
}

.DatePicker__helperWrapper {
  display: flex;
  align-items: flex-start;
}

.DatePicker__helperWrapper:not(:empty) {
  margin-top: 0.25rem;
}

.DatePicker__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

/**
 * Validation & Errors
 */

.DatePicker--valid .SingleDatePickerInput {
  border-color: #6d98ed;
}

.DatePicker__icon--valid {
  color: #8bca2f;
}

.DatePicker--error .SingleDatePickerInput {
  border-color: #b21111;
}

.DatePicker__icon--error {
  color: #b21111;
}

/**
 * NOTE: On mobile, we switch to a full-screen portal which means
 * Blueprints' custom DatePicker class (and its modifiers) will no longer
 * have a parent relationship over the popover. BE CAREFUL using .DatePicker
 * to style anything within the calendar popup (input should still be OK)
 */

.SingleDatePickerInput,
.DateRangePickerInput {
  display: flex;
  align-items: center;
  height: 2rem;
  border-color: #788f9c;
  border-radius: 0.25rem;
}

.SingleDatePickerInput_calendarIcon,
.DateRangePickerInput_calendarIcon {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: -1px;
  bottom: 2px;
  padding: 0;
  line-height: 0;
}

.SingleDatePickerInput_calendarIcon_svg,
.DateRangePickerInput_calendarIcon_svg {
  fill: currentColor;
  color: #788f9c;
}

.DateInput {
  margin-top: 1px;
  background-color: transparent;
}

.DateInput:not(.DateInput__block) {
  width: auto;
}

.DateInput_input {
  padding: 0;
  line-height: normal;
  font-weight: 400;
  color: #3b474d;
  font-size: 1rem;
  border-style: none;
  background-color: transparent;
}

.DateInput_input::-webkit-input-placeholder {
  color: #788f9c;
}

.DateInput_input::-moz-placeholder {
  color: #788f9c;
}

.DateInput_input:-ms-input-placeholder {
  color: #788f9c;
}

.DateInput_input::-ms-input-placeholder {
  color: #788f9c;
}

.DateInput_input::placeholder {
  color: #788f9c;
}

.DatePicker--range__separator {
  margin-right: 0.75rem;
}

/**
 * Focus styling
 */

.DatePicker--focused .SingleDatePickerInput,
.DatePicker--focused .DateRangePickerInput {
  background-color: #ffffff;
  border-color: #6d98ed;
}

.DatePicker--focused .SingleDatePickerInput_calendarIcon_svg,
.DatePicker--focused .DateRangePickerInput_calendarIcon_svg {
  fill: currentColor;
  color: #6d98ed;
}

/**
 * Disabled styling
 */

.SingleDatePickerInput__disabled,
.DateRangePickerInput__disabled {
  background-color: #f4f4f4;
  border-color: #dde2e6;
  cursor: not-allowed;
}

.SingleDatePickerInput__disabled .SingleDatePickerInput_calendarIcon,
.DateRangePickerInput__disabled .SingleDatePickerInput_calendarIcon,
.SingleDatePickerInput__disabled .DateRangePickerInput_calendarIcon,
.DateRangePickerInput__disabled .DateRangePickerInput_calendarIcon {
  cursor: not-allowed;
}

.SingleDatePickerInput__disabled .SingleDatePickerInput_calendarIcon_svg,
.DateRangePickerInput__disabled .SingleDatePickerInput_calendarIcon_svg,
.SingleDatePickerInput__disabled .DateRangePickerInput_calendarIcon_svg,
.DateRangePickerInput__disabled .DateRangePickerInput_calendarIcon_svg {
  fill: currentColor;
  color: #788f9c;
}

.SingleDatePickerInput__disabled .DatePicker--range__separator,
.DateRangePickerInput__disabled .DatePicker--range__separator {
  color: #bbc7cd;
}

.DateInput_input__disabled {
  color: #bbc7cd;
  font-style: normal;
  cursor: not-allowed;
}

.DateInput_input__disabled::-webkit-input-placeholder {
  color: #788f9c;
  opacity: 1;
}

.DateInput_input__disabled::-moz-placeholder {
  color: #788f9c;
  opacity: 1;
}

.DateInput_input__disabled:-ms-input-placeholder {
  color: #788f9c;
  opacity: 1;
}

.DateInput_input__disabled::-ms-input-placeholder {
  color: #788f9c;
  opacity: 1;
}

.DateInput_input__disabled::placeholder {
  color: #788f9c;
  opacity: 1;
}

/**
 * Size variants
 */

.DatePicker--small .DateInput {
  margin-top: 0;
}

.DatePicker--small .DateInput_input {
  font-size: 0.75rem;
}

.DatePicker--small .SingleDatePickerInput,
.DatePicker--small .DateRangePickerInput {
  height: 1.5rem;
}

.DatePicker--small .SingleDatePickerInput_calendarIcon,
.DatePicker--small .DateRangePickerInput_calendarIcon {
  margin-top: 0;
  transform: scale(0.9);
}

.DatePicker--large .DateRangePickerInput_arrow {
  font-size: 1.5rem;
}

.DatePicker--large .SingleDatePickerInput,
.DatePicker--large .DateRangePickerInput {
  height: 3.5rem;
}

.DatePicker--large .SingleDatePickerInput_calendarIcon,
.DatePicker--large .DateRangePickerInput_calendarIcon {
  margin-left: 1rem;
  margin-right: 1rem;
  transform: scale(1.4);
}

.SingleDatePicker_picker,
.DateRangePicker_picker {
  z-index: 40;
}

@media (min-width: 640px) {
  .SingleDatePicker_picker,
  .DateRangePicker_picker {
    margin-top: -1.35rem;
  }

  .DatePicker--small .SingleDatePicker_picker,
  .DatePicker--small .DateRangePicker_picker {
    margin-top: -1.85rem;
  }

  .DatePicker--large .SingleDatePicker_picker,
  .DatePicker--large .DateRangePicker_picker {
    margin-top: 0.2rem;
  }
}

.SingleDatePicker_picker__portal,
.DateRangePicker_picker__portal {
  overflow: auto;
}

.DateInput_fang {
  z-index: 40;
}

@media (min-width: 640px) {
  .DateInput_fang {
    margin-top: -1.55rem;
    margin-left: -2.95rem;
  }

  .DatePicker--small .DateInput_fang {
    margin-top: -1.95rem;
  }

  .DatePicker--large .DateInput_fang {
    margin-top: -0.4rem;
    margin-left: -3.4rem;
  }
}

.DateInput_fangStroke {
  stroke: config('colors.gray-100');
}

/**
 * Calendar styling
 */

.DayPicker_weekHeader {
  margin-top: -0.5rem;
}

.CalendarDay {
  font-size: 0.75rem;
}

.CalendarDay__default {
  border-style: none;
  color: #596b74;
}

.CalendarDay__default:hover,
.CalendarDay__default:active {
  color: #ffffff;
  background-color: #2f6de5;
  border-style: none;
}

.CalendarDay__selected {
  color: #ffffff;
  background-color: #6d98ed;
}

.CalendarDay__blocked_out_of_range {
  color: #dde2e6;
}

.CalendarDay__blocked_out_of_range:hover,
.CalendarDay__blocked_out_of_range:active {
  background-color: transparent;
  color: #dde2e6;
}

.DayPickerKeyboardShortcuts {
  /* stylelint-disable unit-blacklist */
  /* stylelint-enable unit-blacklist */
}

.DayPickerKeyboardShortcuts_panel {
  left: 100%;
  right: unset;
  top: -1px;
  bottom: -1px;
  width: 100%;
  margin: 0;
  margin-left: -2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.DayPickerKeyboardShortcuts_title {
  color: #596b74;
}

/**
 * Toggle button
 */

.DayPickerKeyboardShortcuts_show {
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0.25rem;
  right: 0.25rem;
  text-align: center;
  font-size: 0.75rem;
  border-radius: 9999px;
  background-color: #baccf3;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-style: none;
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  position: static;
}

.KeyboardShortcutRow {
  color: #99abb4;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.KeyboardShortcutRow_key {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  text-transform: uppercase;
  color: #596b74;
  background-color: #f4f4f4;
}

.KeyboardShortcutRow_keyContainer {
  display: inline-block;
  margin-right: 0.25rem;
}

.KeyboardShortcutRow_action {
  display: inline;
}

/**
 * Style highlighted days that show between start & end
 * For specificity overrides, this has to come before the selected start/end day styles
 */

.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  color: #596b74;
  background-color: #dae5f9;
}

.CalendarDay__selected_start,
.CalendarDay__selected_end {
  color: #ffffff;
  background-color: #6d98ed;
}

/**
 * Blocked ranges (min nights)
 */

.CalendarDay__blocked_minimum_nights:not(.CalendarDay__selected) {
  color: #dde2e6;
}

.CalendarDay__blocked_minimum_nights:not(.CalendarDay__selected):hover,
.CalendarDay__blocked_minimum_nights:not(.CalendarDay__selected):active {
  color: #dde2e6;
  background-color: transparent;
}

.CalendarDay__blocked_minimum_nights:not(.CalendarDay__selected).CalendarDay__selected_span:hover,
.CalendarDay__blocked_minimum_nights:not(.CalendarDay__selected).CalendarDay__selected_span:active {
  background-color: #f5f2fc;
}

/**
 * Pre-set ranges - where `endDateOffset` has been specified
 */

.CalendarDay__hovered_offset {
  color: #ffffff;
  background-color: #7141d8;
}

.CalendarDay__hovered_offset:hover,
.CalendarDay__hovered_offset:active {
  background-color: #7141d8;
}

/**
 * Validation & Errors
 */

.DatePicker--valid {
  border-color: #6d98ed;
}

.DatePicker__icon--valid {
  color: #8bca2f;
}

.DatePicker--error .DateRangePickerInput {
  border-color: #b21111;
}

.DatePicker__icon--error {
  color: #b21111;
}

.DateRangePicker .DayPicker > div {
  display: flex;
  align-items: stretch;
}

.DayPicker_calendarInfo__horizontal {
  border-right-width: 1px;
}

.PresetDatesPanel {
  max-height: 17rem;
  margin: -1rem 1rem 2rem;
  padding-top: 0.5rem;
  border-top-width: 1px;
  overflow-y: auto;
}

@media (min-width: 640px) {
  .PresetDatesPanel {
    position: relative;
    width: 10.5rem;
    margin: 0;
    padding-top: 0;
    border-width: 0;
  }
}

.PresetDatesPanel__preset {
  display: inline-block;
  width: 50%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  text-align: left;
  color: #596b74;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

@media (min-width: 640px) {
  .PresetDatesPanel__preset {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding-left: 1.6rem;
  }

  .PresetDatesPanel__preset:first-child {
    margin-top: 0.75rem;
  }

  .PresetDatesPanel__preset--custom {
    margin-bottom: 0.5rem;
    padding-top: 1rem;
    border-top-width: 1px;
  }
}

.PresetDatesPanel__preset--active::before {
  position: absolute;
  margin-left: -1rem;
  margin-top: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 9999px;
  background-color: #6d98ed;
  content: '';
}
