.ExpandableCard__container {
  position: relative;
}

.ExpandableCard__label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7141d8;
  font-size: 0.875rem;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.ExpandableCard__content {
  position: absolute;
  z-index: 10;
  background-color: #ffffff;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 2.18rem 1.875rem 0 rgba(0, 0, 0, 0.11), 0 0.93rem 0.93rem 0 rgba(0, 0, 0, 0.08);
}
