.PulsedCheckMark__canvas {
  position: relative;
  width: 3rem;
  height: 3rem;
}

.PulsedCheckMark__background {
  fill: white;
  transform: scale(0.3) translate(56px, 56px);
}

.PulsedCheckMark__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
}

.PulsedCheckMark__icon {
  fill: #8bca2f;
  transform: scale(1.2) translate(10px, 10px);
}

.pulse {
  position: absolute;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  transform: scale(1);
  background: #8bca2f;
  box-shadow: 0 0 0 0 #add96d;
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(173, 217, 109, 0.8);
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 1.2rem rgba(173, 217, 109, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(173, 217, 109, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(173, 217, 109, 0.8);
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 1.2rem rgba(173, 217, 109, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(173, 217, 109, 0);
  }
}
