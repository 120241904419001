.HelperTooltipWrapper {
  display: inline-block;
}

.HelperTooltip {
  z-index: 50;
  pointer-events: auto;
  position: relative;
  padding: 0.5rem;
  border-radius: 0.25rem;
  color: #ffffff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  text-align: center;
  font-size: 1rem;
  visibility: hidden;
}

.HelperTooltip--visible {
  visibility: visible;
}

.HelperTooltip__arrow {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  transform: rotate(45deg);
}

.HelperTooltip--top {
  bottom: 0.5rem;
}

.HelperTooltip--bottom {
  top: 0.5rem;
}

.HelperTooltip--right {
  left: 0.5rem;
}

.HelperTooltip--left {
  right: 0.5rem;
}

.HelperTooltip__arrow--left,
.HelperTooltip__arrow--right {
  bottom: calc(50% - 0.25rem);
}

.HelperTooltip__arrow--top,
.HelperTooltip__arrow--bottom {
  left: calc(50% - 0.25rem);
}

.HelperTooltip__arrow--left {
  right: -0.25rem;
}

.HelperTooltip__arrow--top {
  bottom: -0.25rem;
}

.HelperTooltip__arrow--right {
  left: -0.25rem;
}

.HelperTooltip__arrow--bottom {
  top: -0.25rem;
}
