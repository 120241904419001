.Tabs {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.Tabs__list {
  display: table;
  width: 100%;
  white-space: nowrap;
  border-bottom-width: 1px;
  border-color: #bbc7cd;
  padding-bottom: 1px;
}

.Tab {
  display: inline-block;
}

.Tab__button {
  position: relative;
  font-size: 0.75rem;
  color: #596b74;
  font-weight: 400;
  font-family: Noto Sans, sans-serif;
  text-transform: uppercase;
  line-height: 1.5;
  transition-property: color;
  transition-duration: 133ms;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.375rem;
  /**
   * Underline
   */
}

@media (min-width: 768px) {
  .Tab__button {
    font-size: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.Tab__button:hover,
.Tab__button.focus-visible {
  color: #7141d8;
}

.Tab__button:active {
  color: #6d98ed;
}

.Tab__button::after {
  content: '';
  position: absolute;
  background-color: #7141d8;
  height: 3px;
  bottom: -2px;
  right: 0;
  left: 0;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 133ms;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
}

/**
 * Active tab
 */

.Tab--active {
  pointer-events: none;
}

.Tab--active .Tab__button {
  color: #3b474d;
  font-weight: 500;
}

.Tab--active .Tab__button::after {
  opacity: 1;
}

/**
 * Disabled tab
 */

.Tab--disabled {
  cursor: not-allowed;
}

.Tab--disabled .Tab__button {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #bbc7cd;
}
