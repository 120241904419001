.Navbar {
  display: flex;
  background-color: #ffffff;
  border-bottom-width: 1px;
  border-color: #bbc7cd;
  min-height: 45px;
  max-height: 45px;
}

.Navbar__container {
  position: relative;
  display: flex;
  flex: 1 1 0%;
  align-items: stretch;
  justify-content: space-between;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}
