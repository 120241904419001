.PromotionalBannerWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 0.0625rem solid;
  border-color: #dde2e6;
  width: 100%;
  max-width: 24.625rem;
  overflow: hidden;
}

.PromotionalBannerWrapper::before {
  content: '';
  background: radial-gradient(circle at -8% 77%, #4e23ab, #8a239c 49%, #c64876 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.25rem;
}

@media (min-width: 768px) {
  .PromotionalBannerWrapper {
    flex-direction: row;
    max-width: 78rem;
  }
}
