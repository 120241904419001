.Table__TableRow {
  border-bottom-width: 1px;
  border-left-width: 0;
  color: #3b474d;
  padding: 0.25rem;
}

.Table__TableRow:last-child {
  border-width: 0;
}

/**
 * Selected
 */

.Table__TableRow--selected {
  background-color: #edf2fc;
  border-color: #dae5f9;
}

.Table__TableRow--selected .Table__TableData,
.Table__TableRow--selected .Table__TableHeader,
.Table__TableRow--selected .Table__TableHeader[scope='row']::after {
  border-color: #baccf3;
}

/**
 * Hover styling
 */

.Table__TableRow--hoverable:hover {
  background-color: #f3eaf5;
  border-color: #e1c7e6;
  box-shadow: inset 0 -2px 0 -1px #e1c7e6;
}

.Table__TableRow--hoverable:hover .Table__TableHeader,
.Table__TableRow--hoverable:hover .Table__TableHeader[scope='row']::after {
  border-color: #c491cd;
}

.Table__TableRow--hoverable:hover .Table__TableData {
  border-color: #e1c7e6;
}

.Table__TableRow--hoverable:hover .Table__TableData:not(.Table__TableData--red):not(.Table__TableData--green) {
  color: #53155e;
}

/**
 * Condensed
 */

.Table__TableRow--condensed .Table__TableData,
.Table__TableRow--condensed .Table__TableHeader {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
