.ListItem {
  color: #596b74;
}

ul .ListItem::before {
  content: '\2022';
  color: #596b74;
  display: inline-block;
  width: 1rem;
  margin-left: 1rem;
}

ol .ListItem {
  counter-increment: li;
}

ol .ListItem::before {
  content: counter(li) '.';
  color: #596b74;
  display: inline-block;
  width: 1rem;
  margin-left: 1rem;
}
