/* stylelint-disable no-duplicate-selectors */

.MobileNavBurger {
  z-index: 20;
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  padding-left: 1rem;
  border-bottom-width: 1px;
  border-color: #bbc7cd;
  margin-bottom: -1px;
  color: #3b474d;
  font-family: Noto Sans, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  line-height: 1;
}

.MobileNavBurger.MobileNavBurger--open {
  border-color: #ffffff;
}

@media (min-width: 1024px) {
  .MobileNavBurger {
    display: none;
  }
}

.MobileNavBurger__label {
  display: block;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
}

/**
 * Hamburger icon/animation + credits
 *
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.MobileNavBurger__menuIcon {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 17px;
}

.MobileNavBurger__menuIcon .MobileNavBurger__hamburger {
  display: block;
  margin-top: -1.5px;
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.MobileNavBurger__menuIcon .MobileNavBurger__hamburger,
.MobileNavBurger__menuIcon .MobileNavBurger__hamburger::before,
.MobileNavBurger__menuIcon .MobileNavBurger__hamburger::after {
  position: absolute;
  width: 2rem;
  height: 3px;
  background-color: #7141d8;
  border-radius: 0.25rem;
  transition-property: transform;
  transition-duration: 0.15s;
}

.MobileNavBurger__menuIcon .MobileNavBurger__hamburger::before,
.MobileNavBurger__menuIcon .MobileNavBurger__hamburger::after {
  display: block;
  content: '';
}

.MobileNavBurger__menuIcon .MobileNavBurger__hamburger::after {
  top: -14px;
  bottom: -7px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.MobileNavBurger__menuIcon .MobileNavBurger__hamburger::before {
  top: -7px;
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.MobileNavBurger--open .MobileNavBurger__hamburger {
  transform: translate3d(0, -7px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.MobileNavBurger--open .MobileNavBurger__hamburger,
.MobileNavBurger--open .MobileNavBurger__hamburger::before,
.MobileNavBurger--open .MobileNavBurger__hamburger::after {
  background-color: #7141d8;
}

.MobileNavBurger--open .MobileNavBurger__hamburger::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.MobileNavBurger--open .MobileNavBurger__hamburger::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.MobileNav {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  top: 90px;
  background-color: rgba(32, 32, 32, 0.5);
}

.MobileNav__items {
  background-color: #ffffff;
  width: 100%;
}

.MobileNav__item {
  border-bottom-width: 1px;
  border-color: #bbc7cd;
}

.MobileNav__linkWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.MobileNav__label .NavLinks__linkIcon--newWindow {
  margin-bottom: 1px;
}

.MobileNav__link {
  position: relative;
  font-size: 1.25rem;
  color: #3b474d;
  font-family: Noto Sans, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
}

.MobileNav__link--active,
.MobileSubNav__link--active,
.MobileNav__link.active,
.MobileSubNav__link.active {
  box-shadow: none;
}

.MobileNav__link--active:before,
.MobileSubNav__link--active:before,
.MobileNav__link.active:before,
.MobileSubNav__link.active:before {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #7141d8;
  border-radius: 9999px;
  content: ' ';
  left: -1rem;
  top: 0.3rem;
}

.MobileSubNav {
  visibility: hidden;
  overflow: hidden;
  height: 0;
}

.MobileSubNav--open {
  visibility: visible;
  height: auto;
}

.MobileSubNav__link {
  position: relative;
  font-size: 1.25rem;
  color: #3b474d;
  font-family: Noto Sans, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  font-size: 1rem;
}

.MobileSubNav__link--active,
.MobileSubNav__link.active {
  box-shadow: none;
}

.MobileSubNav__link--active:before,
.MobileSubNav__link.active:before {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #7141d8;
  border-radius: 9999px;
  content: ' ';
  left: -1rem;
  top: 0.3rem;
}

.MobileSubNav__link:hover,
.MobileSubNav__link:active,
.MobileSubNav__link.focus-visible {
  color: #7141d8;
}

.MobileNav__linkIcon {
  transition-property: transform;
  transition-duration: 200ms;
}

.MobileNav__linkIcon--open {
  transform: rotate(180deg);
}

.MobileSubNav__link--active:before,
.MobileSubNav__link.active:before {
  top: 0.2rem;
}
