.ControlStrip {
  background-color: #ffffff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 768px) {
  .ControlStrip {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.ControlStrip .Tag {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.ControlStrip .AdvancedSelect,
.ControlStrip .AdvancedSelectWrapper,
.ControlStrip .Select,
.ControlStrip .SearchBar,
.ControlStrip .TextInput,
.ControlStrip .DatePicker {
  width: 100%;
}

@media (min-width: 768px) {
  .ControlStrip .AdvancedSelect,
  .ControlStrip .AdvancedSelectWrapper,
  .ControlStrip .Select,
  .ControlStrip .SearchBar,
  .ControlStrip .TextInput,
  .ControlStrip .DatePicker {
    width: 16rem;
  }
}

.ControlStrip .AdvancedSelectWrapper,
.ControlStrip .Select,
.ControlStrip .SearchBar,
.ControlStrip .TextInput,
.ControlStrip .DatePicker {
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 768px) {
  .ControlStrip .AdvancedSelectWrapper,
  .ControlStrip .Select,
  .ControlStrip .SearchBar,
  .ControlStrip .TextInput,
  .ControlStrip .DatePicker {
    width: 16rem;
    margin-right: 0.75rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}

.ControlStrip--wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .ControlStrip--wrapper {
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.ControlStrip--withLabels .AdvancedSelect,
.ControlStrip--withLabels .AdvancedSelectWrapper,
.ControlStrip--withLabels .Select,
.ControlStrip--withLabels .TextInput,
.ControlStrip--withLabels .DatePicker,
.ControlStrip--withLabels .SearchBar {
  width: 100%;
}

@media (min-width: 768px) {
  .ControlStrip--withLabels .AdvancedSelect,
  .ControlStrip--withLabels .AdvancedSelectWrapper,
  .ControlStrip--withLabels .Select,
  .ControlStrip--withLabels .TextInput,
  .ControlStrip--withLabels .DatePicker,
  .ControlStrip--withLabels .SearchBar {
    width: 16rem;
  }
}

.ControlStrip--withLabels .AdvancedSelect,
.ControlStrip--withLabels .Select,
.ControlStrip--withLabels .TextInput,
.ControlStrip--withLabels .DatePicker,
.ControlStrip--withLabels .SearchBar {
  margin-right: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .ControlStrip--withLabels .AdvancedSelect,
  .ControlStrip--withLabels .Select,
  .ControlStrip--withLabels .TextInput,
  .ControlStrip--withLabels .DatePicker,
  .ControlStrip--withLabels .SearchBar {
    width: 16rem;
    margin-top: 0;
    margin-bottom: 0.25rem;
    margin-right: 0.75rem;
  }
}

.ControlStrip--withLabels .Tag {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.ControlStrip--filters {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .ControlStrip--filters {
    flex-direction: row;
  }
}

.ControlStrip--filters .Button {
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.ControlStrip--withLabelsHeight {
  min-height: 6rem;
}

.ControlStrip--filterBelow {
  margin-bottom: 0.125rem !important;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-left: 0 !important;
}

.ControlStrip--filterBelow .Tag {
  margin-bottom: 0;
}

.ControlStrip--filterBelow .Button--secondary {
  margin-bottom: 0.25rem;
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 768px) {
  .ControlStrip--filterBelow .Button--secondary {
    margin-top: 0.25rem;
    margin-bottom: 0;
    margin-right: 0.75rem;
  }
}

.ControlStrip--actionButtons {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.ControlStrip--actionButtons .Icon {
  width: 1.5rem;
  height: 1.5rem;
}

.ControlStrip--actionButtons .Button {
  height: 2rem;
}

.ControlStrip--actionButtons .Button--secondary {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

@media (min-width: 768px) {
  .ControlStrip--actionButtons .Button--secondary {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    width: unset;
  }
}

.ControlStrip--actionButtons .Button--tertiary {
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 768px) {
  .ControlStrip--actionButtons .Button--tertiary {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.ControlStrip--actionButtons .Button--tertiary:last-child {
  padding-right: 0;
  margin-right: 0;
}

.ControlStrip .AdvancedSelect__control {
  height: 2rem;
}

.ControlStrip--onlyActionButtons {
  justify-content: flex-end;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ControlStrip--onlyActionButtons .Button {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.ControlStrip .Filter {
  margin-bottom: 0;
  margin-right: 0;
  width: 100%;
}

.ControlStrip .Filter .Button {
  width: 100%;
}

@media (min-width: 768px) {
  .ControlStrip .Filter {
    width: unset;
    margin-right: 0.75rem;
  }

  .ControlStrip .Filter .Button {
    width: unset;
  }
}
