.TextArea {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.TextArea__childrenContainer {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

.TextArea__element {
  transition-timing-function: cubic-bezier(0.45, 0, 1, 1);
  transition-duration: 67ms, 133ms;
  transition-property: all, background-color;
  caret-color: #596b74;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  border-color: #788f9c;
  font-family: Noto Sans, sans-serif;
  line-height: 1.25;
  color: #596b74;
  font-size: 1rem;
  padding: 0.5rem;
  max-width: 100%;
  align-self: flex-start;
  flex: 1 1 0%;
  resize: none;
  box-sizing: border-box;
}

.TextArea__element--resizable {
  resize: both;
}

.TextArea__element--small {
  font-size: 0.75rem;
}

.TextArea__element--large {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.TextArea__element::-webkit-input-placeholder {
  color: #788f9c;
}

.TextArea__element::-moz-placeholder {
  color: #788f9c;
}

.TextArea__element:-ms-input-placeholder {
  color: #788f9c;
}

.TextArea__element::-ms-input-placeholder {
  color: #788f9c;
}

.TextArea__element::placeholder {
  color: #788f9c;
}

.TextArea__element:focus {
  border-color: #6d98ed;
  outline: 0;
}

.TextArea__element--disabled,
.TextArea__element--disabled:focus {
  cursor: not-allowed;
  border-width: 1px;
  border-color: #dde2e6;
  color: #99abb4;
  background-color: #f4f4f4;
}

.TextArea__helperWrapper {
  display: flex;
  align-items: flex-start;
}

.TextArea__helperWrapper:not(:empty) {
  margin-top: 0.25rem;
}

.TextArea__helperText {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.TextArea__charLimit {
  margin-left: 1rem;
}

.TextArea__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

/**
 * Validation & Errors
 */

.TextArea__element--valid {
  border-color: #6d98ed;
}

.TextArea__icon--valid {
  color: #8bca2f;
}

.TextArea__element--error {
  border-color: #b21111;
}

.TextArea__icon--error {
  color: #b21111;
}
