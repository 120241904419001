.Button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: Noto Sans, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  margin: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 2rem;
  position: relative;
  top: 0;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: transparent;
  background-color: transparent;
}

.Button--loading .NoIconSpinner {
  position: absolute;
  left: 0.25rem;
}

.Button--loading.Button--large .NoIconSpinner {
  left: 0.5rem;
}

.Button .PopoverIcon__up {
  transform: rotate(60deg);
}

.Button__icon {
  display: inline-block;
  width: 1rem;
  margin-right: 0.5rem;
}

.Button__icon,
.Button .Icon,
.Button .LoadingSpinner {
  vertical-align: top;
}

.Button__container {
  display: inline-flex;
  align-items: center;
}

/**
 * Size variants
 */

.Button--small {
  font-size: 0.625rem;
  line-height: 1.25;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  min-height: 1.5rem;
}

.Button--small .Button__icon {
  width: 0.75rem;
  margin-right: 0.25rem;
}

.Button--small .Button__icon .Icon {
  height: 0.75rem;
}

.Button--small .LoadingSpinner {
  height: 0.75rem;
  margin-left: -0.25rem;
}

.Button--large {
  font-size: 1.125rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
  min-height: 3rem;
}

.Button--large .Button__icon {
  width: 1.5rem;
}

.Button--large .Icon,
.Button--large .LoadingSpinner,
.Button--large .LoadingSpinner__svg {
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
}

.Button--fullWidth {
  width: 100%;
}

/**
 * Hierarchy variants
 */

.Button--primary {
  color: #ffffff;
  background-color: #7141d8;
  border-bottom: none;
}

.Button--primary:hover,
.Button--primary.focus-visible {
  background-color: #4e23ab;
}

.Button--primary:active,
.Button--primary.Button--pressed {
  background-color: #391a7c;
}

.Button--secondary {
  color: #7141d8;
  border-color: #7141d8;
  background-color: #ffffff;
}

.Button--secondary:hover,
.Button--secondary.focus-visible {
  color: #4e23ab;
  border-color: #4e23ab;
}

.Button--secondary:active,
.Button--secondary.Button--pressed {
  color: #391a7c;
  border-color: #391a7c;
}

.Button--tertiary {
  top: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #7141d8;
  background-color: transparent;
}

.Button--tertiary:hover,
.Button--tertiary.focus-visible {
  color: #4e23ab;
}

.Button--tertiary:active,
.Button--tertiary.Button--pressed {
  color: #391a7c;
}

/**
 * State variants
 */

.Button--disabled {
  color: #596b74;
  background-color: #dde2e6;
  cursor: not-allowed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Button--disabled:hover,
.Button--disabled.focus-visible {
  background-color: #dde2e6;
}

.Button--disabled.Button--secondary {
  color: #99abb4;
  border-color: #bbc7cd;
}

.Button--disabled.Button--tertiary {
  color: #99abb4;
  background-color: transparent;
}

.Button--menu {
  padding: 0;
}

.Button--menu .Button__label {
  padding: 0.5rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Button--menu .PopoverWrapper {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-left: 0.0625rem solid #7141d8;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.Button--menu .PopoverWrapper .PopoverIcon {
  display: flex;
}

.Button--primary.Button--menu .PopoverWrapper {
  border-left: 0.0625rem solid #4e23ab;
}

.Button--primary.Button--menu:hover .PopoverWrapper {
  border-left: 0.0625rem solid #7141d8;
}

.Button--menu.Button--large .Button__label {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.Button--menu.Button--large .PopoverWrapper {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.Button--menu.Button--small .Button__label {
  padding-top: 0;
  padding-bottom: 0;
}

.Button--menu.Button--small .PopoverWrapper {
  padding-right: 0.35rem;
  padding-left: 0.35rem;
}

.Button--menu.Button--small .PopoverWrapper .PopoverIcon {
  width: 1.25rem;
}
