.List {
  font-family: Noto Sans, sans-serif;
  font-weight: 400;
  color: #99abb4;
  font-size: 1rem;
  line-height: 2;
  letter-spacing: 0;
}

ol + .List {
  counter-reset: li;
}
