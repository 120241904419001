.mid-text {
	text-align: left;
	text-transform: capitalize;
	margin-right: 5px;
	font-size: 12px;
}

.mid-text-navigation {
	border-radius: 0 !important;
	.Icon {
		color: #788f9c;
	}
}

.mid-text-navigation-disabled {
	border-radius: 0 !important;
	cursor: not-allowed;
	pointer-events: none;
}

.Header .NavigationBar {
	height: 50px;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
