.CardMedia {
  display: flex;
  justify-content: center;
  position: relative;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.CardMedia__tag {
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  text-align: center;
}

.CardMedia__Image {
  width: 100%;
  max-height: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .CardMedia__Image.object-scale-down {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (min-width: 768px) {
  .CardMedia__Image.object-scale-down + .CardMedia__tag {
    padding-top: 1rem;
    padding-right: 1rem;
  }
}

.CardMedia--sm {
  height: 6rem;
}

.CardMedia--sm .CardMedia__Fallback {
  max-height: 100%;
}

.CardMedia--md {
  height: 12rem;
}
