.AdvancedSwitcher {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.AdvancedSwitcher .AdvancedSwitcherBody {
  flex: 1 1 0%;
  overflow-y: auto;
  padding-top: 1.25rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.AdvancedSwitcher .AdvancedSwitcher_RadioButtons {
  margin-top: 0;
  margin-bottom: 0;
}
