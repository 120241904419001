.CardGroup__background--purple {
  background-image: radial-gradient(circle at -8% 77%, #4e23ab, #8a239c 49%, #c64876 100%);
}

.CardGroup__background--gray {
  background-color: #dde2e6;
}

.CardWrapper {
  display: inline-block;
}
