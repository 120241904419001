.Bookmarklet__wrapper {
  display: inline-block;
}

.Bookmarklet__wrapper .Icon {
  transform: rotate(45deg);
}

.Bookmarklet__wrapper .Bookmarklet__text {
  padding-right: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.Bookmarklet__wrapper .Bookmarklet__link {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border-width: 1px;
  background-color: #ffffff;
  border-color: #dde2e6;
  color: #7141d8;
}

.Bookmarklet__wrapper .Bookmarklet__link p {
  color: #7141d8;
}

.Bookmarklet__wrapper .Bookmarklet__link div {
  color: #7141d8;
}
