.Text {
  font-size: 1rem;
  color: #3b474d;
  font-family: Noto Sans, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.Text--sm {
  font-size: 0.625rem;
  font-weight: 600;
}

.Text--strong {
  font-weight: 700;
}

.Pagination {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: baseline;
}

.Pagination__select {
  display: flex;
  align-items: baseline;
  font-size: 1rem;
  color: #3b474d;
  font-family: Noto Sans, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.875rem;
}

.Pagination__buttons {
  justify-self: center;
}

.Pagination__range {
  justify-self: end;
  font-size: 1rem;
  color: #3b474d;
  font-family: Noto Sans, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.875rem;
}

.Pagination__dots {
  text-align: center;
  display: inline-block;
  width: 2rem;
  margin: 0.25rem;
  color: #9fb4e7;
  border-width: 1px;
  border-color: transparent;
}

.Pagination__text {
  display: none;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 133ms;
  transition-property: visibility;
}

.Pagination__text--active {
  display: inline-block;
}

.Pagination__text:first-of-type {
  margin-right: 0.5rem;
  text-align: right;
}

.Pagination__text:last-of-type {
  margin-left: 0.5rem;
  text-align: left;
}

.Pagination__textButton {
  font-weight: 600;
  font-size: 0.75rem;
  color: #6d98ed;
  font-family: Noto Sans, sans-serif;
}

.Pagination__number {
  display: inline-block;
  width: 2rem;
  margin: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6d98ed;
  font-size: 0.75rem;
  text-align: center;
  font-weight: 600;
  font-family: Noto Sans, sans-serif;
  line-height: 1;
  background-color: #ffffff;
  border-width: 1px;
  border-radius: 0.25rem;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 133ms;
  transition-property: color, background-color;
}

.Pagination__number:hover {
  color: #ffffff;
  background-color: #4e23ab;
  border-color: #4e23ab;
}

.Pagination__number--active {
  color: #ffffff;
  background-color: #6d98ed;
  border-color: #6d98ed;
}
