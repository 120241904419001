.AppSwitcher__wrapper {
  cursor: pointer;
  width: 2rem;
  position: relative;
  margin-left: auto;
}

.AppSwitcher__wrapper .AppSwitcher__DottedMenu .Icon--app-switcher {
  width: 1.5rem;
  height: 1.5rem;
  color: #ffffff;
}

.AppSwitcher__wrapper .Card {
  padding-left: 2rem;
  padding-right: 2rem;
  width: 28rem;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
}

.AppSwitcher__wrapper .Card .CardHeader__subHeader {
  color: #3b474d;
}

.AppSwitcher__wrapper .Card .Text {
  font-size: 0.75rem;
}

.AppSwitcher__wrapper .Card .AppSwitcher__cardSection_item:not(:last-child) {
  border-bottom: 0.1rem solid #dde2e6;
  padding-bottom: 1rem;
}

.AppSwitcher__wrapper .Card .AppSwitcher__card_footerWrapper:not(:last-child) {
  margin-bottom: 0.25rem;
}

.AppSwitcher__wrapper .AppSwitcher__card.block {
  z-index: 49;
  -webkit-animation: fade-in 0.1s;
          animation: fade-in 0.1s;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
