.Card {
  padding: 1rem;
  border-width: 1px;
  border-color: #dde2e6;
  border-radius: 0.25rem;
  background-color: #ffffff;
  font-family: Noto Sans, sans-serif;
  color: #3b474d;
  transition: transform 0.2s linear;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 133ms;
  transition-property: box-shadow;
}

.Card .CardMedia__Image.object-cover,
.Card .CardMedia__Image.object-fill {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.Card .ThreeDotMenu {
  align-self: center;
  padding-left: 1rem;
}

.CardFooter__container {
  display: flex;
}

.CardFooter__container :first-child {
  flex-grow: 1;
}

.Card--isHovered {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.Card--noTopPadding {
  padding-top: 0;
}

.Card--expanded {
  position: relative;
  display: inline-block;
  z-index: 10;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transform: scale(1.02);
  transform-origin: 50% 0;
}

@media (max-width: 768px) {
  .Card--hasMobileRow .Card__container {
    display: flex;
    flex-direction: row;
  }

  .Card--hasMobileRow .Card__children {
    width: 100%;
  }

  .Card--hasMobileRow .CardMedia {
    margin-left: 0;
    margin-right: 1.5rem;
    margin-top: 1rem;
    width: 33.333333%;
    flex-shrink: 0;
  }

  .Card--hasMobileRow .CardMedia--sm {
    padding-top: 0;
  }

  .Card--hasMobileRow .CardMedia--md {
    margin-top: 0;
    height: 8rem;
  }
}

.Card--clickable {
  cursor: pointer;
}

.Card--active {
  border-color: #6d98ed;
  border-width: 2px;
  border-radius: 0.25rem;
}

.Card__fallback {
  font-style: italic;
  color: #99abb4 !important;
}
