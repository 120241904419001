.NestedCheckbox {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  min-height: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border-width: 1px;
  box-shadow: 0 0 0 1px #788f9c;
}

.NestedCheckbox--checked {
  box-shadow: 0 0 0 2px #7141d8;
}

.NestedCheckbox--tooltip .NestedCheckbox__label {
  margin-right: 3rem;
}

.NestedCheckbox--tooltip .NestedCheckbox__subLabel {
  margin-right: 2rem;
}

.NestedCheckbox__labelContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1.5rem;
}

.NestedCheckbox__labelContainer--hasSubLabel {
  padding-top: 0.25rem;
}

.NestedCheckbox__label {
  display: flex;
  align-items: center;
  color: #3b474d;
}

.NestedCheckbox__label span {
  /* Initial values */
  overflow: hidden;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 3rem;
}

.NestedCheckboxInput {
  cursor: pointer;
  flex-shrink: 0;
  align-self: flex-start;
  width: 1rem;
  height: 1rem;
  margin-top: 0.25rem;
  margin-right: 0.5rem;
}

.NestedCheckboxInput.focus-visible + .Checkbox {
  /* Non-webkit focus styles - FF, IE, etc. */
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .NestedCheckboxInput.focus-visible + .Checkbox {
    /* Webkit native focus styles */
    outline: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.NestedCheckbox__subLabel {
  font-size: 0.75rem;
  color: #596b74;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  margin-left: 1.5rem;
  /* Initial values */
  overflow: hidden;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 3.375rem;
}

.NestedCheckbox__TooltipWrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.NestedCheckbox__TooltipIconWrapper {
  padding-right: 1.25rem;
  margin-top: -1px;
  height: 4rem;
  display: flex;
  align-items: center;
}

.NestedCheckbox__TooltipIcon {
  vertical-align: text-bottom;
  color: #7141d8;
  width: 1.25rem;
  height: 1.25rem;
}

.NestedCheckbox__helperWrapper {
  display: flex;
  align-items: flex-start;
}

.NestedCheckbox__helperWrapper:not(:empty) {
  margin-top: 0.25rem;
}

.NestedCheckbox__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

/**
 * Validation & Errors
 */

.NestedCheckbox--valid {
  border-color: #6d98ed;
  align-items: flex-start;
  flex-direction: column;
}

.NestedCheckbox--required {
  border-color: #6d98ed;
  align-items: flex-start;
  flex-direction: column;
}

.NestedCheckbox--required .HelperText {
  margin-left: 1rem;
}

.NestedCheckbox__icon--valid {
  color: #8bca2f;
}
