.StarRating {
  display: inline-flex;
  white-space: nowrap;
}

.StarRating .Star {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.25rem;
}

.StarRating .Star:last-child {
  margin: 0;
}

.StarRating--small .Star {
  width: 1rem;
  height: 1rem;
  margin-right: 2px;
}
