.Accordion {
  font-family: Noto Sans, sans-serif;
  background-color: #ffffff;
  border-width: 1px;
  border-color: #f4f4f4;
  border-style: solid;
  border-radius: 0.25rem;
}

.Accordion + .Accordion {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -3px;
}

/**
 * Summary / toggle / heading
 */

.Accordion__toggle {
  padding: 0.75rem;
  background-color: #ffffff;
  font-size: 1.25rem;
  color: #788f9c;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 0.25rem;
  list-style: none;
}

.Accordion__toggle::-webkit-details-marker {
  display: none;
}

.Accordion__icon {
  color: #7141d8;
  transition-property: transform;
  transition-duration: 200ms;
  width: 1em;
  height: 1em;
}

.Accordion__icon::before {
  position: static;
}

.Accordion__icon--open {
  transform: rotate(180deg);
}

.Accordion__heading {
  padding-left: 0.75rem;
}

.Accordion__headingWrapper {
  display: flex;
  align-items: center;
}

/**
 * Details / list
 */

.Accordion__list {
  overflow: auto;
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-property: height;
}

.Accordion__item {
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

/**
 * Small variant
 */

.Accordion .Accordion--small {
  border-color: transparent;
  border-radius: 0;
}

.Accordion__wrapper {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Accordion__toggle--small {
  padding: 0.5rem;
  font-size: 1rem;
  color: #596b74;
}

.Accordion__heading--small {
  padding-left: 0.5rem;
}

.Accordion__icon--small {
  font-size: 0.75rem;
}

.Accordion__item--small {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
