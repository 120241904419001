.Heading {
  font-family: Noto Sans, sans-serif;
}

.Heading--1 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .Heading--1 {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.3;
  }
}

@media (min-width: 1024px) {
  .Heading--1 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
}

.Heading--2 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .Heading--2 {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 1.3;
  }
}

@media (min-width: 1024px) {
  .Heading--2 {
    font-size: 2rem;
    font-weight: 300;
  }
}

.Heading--3 {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .Heading--3 {
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

@media (min-width: 1024px) {
  .Heading--3 {
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

.Heading--4 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}
