.FooterLinks__group {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  flex-shrink: 1;
  flex-basis: 23%;
}

.Text.FooterLinks__title {
  color: #3b474d;
  font-size: 0.875rem;
  font-weight: 700;
}

.Footer__link {
  margin-bottom: 0.75rem;
}

.Footer__links {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
