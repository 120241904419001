.ModalContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Modal {
  position: relative;
}

.Modal__content {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 0.5rem;
  border-width: 1px;
  min-height: 10rem;
  max-height: calc(100vh - 2rem);
  min-width: 10rem;
  width: calc(100vw - 2rem);
}

@media (min-width: 768px) {
  .Modal__content {
    width: auto;
    max-width: calc(100vw - 8rem);
    max-height: calc(100vh - 8rem);
  }
}

.Modal::after {
  z-index: -1;
  content: '';
  position: absolute;
  bottom: -3.5rem;
  width: 100%;
  height: 4rem;
  background-image: radial-gradient(100% 5rem at top, #3b474d 0%, rgba(79, 79, 79, 0) 45%);
}

.Modal__close {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  color: #596b74;
  border-bottom-left-radius: 0.5rem;
}

@media (min-width: 768px) {
  .Modal__close {
    margin-top: 1.25rem;
    margin-right: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}

.Modal__close_button {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}

.Blanket--leave.Blanket--leaveActive .ModalContainer {
  display: none;
}

.Blanket--isMounted .ModalContainer,
.Blanket--enter.Blanket--enterActive .ModalContainer {
  display: flex;
}
