.SearchModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  bottom: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.SearchModal .SearchBarContainer {
  background-color: #ffffff;
  border-radius: 0.5rem;
  flex-grow: 1;
  min-width: 12rem;
  max-width: 48rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.SearchModal .AdvancedSearchBar {
  margin: 1rem;
}

.SearchModal .AdvancedSearchBar:focus-within {
  box-shadow: none;
}

.SearchModal .AdvancedSearchBar__formWrapper {
  border-style: none;
}

.SearchModal .AdvancedSearchBar__select {
  border-top-width: 0;
  border-bottom-width: 0;
}

.SearchModal .AdvancedSearchBar .Button {
  padding-left: 1rem;
  padding-right: 1rem;
}

.Blanket--leave.Blanket--leaveActive .SearchModal {
  display: none;
}

.Blanket--isMounted .SearchModal,
.Blanket--enter.Blanket--enterActive .SearchModal {
  display: flex;
}
