.Label {
  background-color: #e64f4f;
  color: #ffffff;
  border-radius: 0.25rem;
  font-weight: 700;
  font-size: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0.5rem;
  vertical-align: middle;
  border-width: 2px;
  border-color: #e64f4f;
}

.Label--secondary {
  background-color: #ffffff;
  color: #e64f4f;
}
