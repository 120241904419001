.AdvancedSwitcherFooter {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #ffffff;
  box-shadow: 0 -0.001rem 0.25rem 0.125rem rgba(0, 0, 0, 0.3);
}

.AdvancedSwitcherFooter .AdvancedSwitcherFooter__link {
  cursor: pointer;
  display: flex;
}

.AdvancedSwitcherFooter .TextLink__icon {
  width: 0.75rem;
  height: 0.75rem;
  display: flex;
  align-self: center;
  top: 0;
}
