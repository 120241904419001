.Tooltip {
  z-index: 40;
  visibility: hidden;
  opacity: 0;
  transition-property: opacity, visibility;
  transition-duration: 67ms;
  transition-timing-function: linear;
  position: relative;
  font-family: Noto Sans, sans-serif;
  background-color: #ffffff;
  border-width: 1px;
  border-radius: 0.25rem;
  box-shadow: 0 -0.75rem 2.5rem -1rem rgba(0, 0, 0, 0.25);
  max-width: 22rem;
  color: #3b474d;
}

.Tooltip__Overlay {
  display: none;
  width: 100vw;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 35;
}

.Tooltip__OverlayShow {
  display: block;
}

.TooltipWrapper {
  display: inline-block;
  width: auto;
}

.Tooltip__close {
  position: absolute;
  right: 0;
  top: 0;
  width: 2.25rem;
  line-height: 1;
  color: #99abb4;
  padding: 0.65rem 0.7rem;
  margin-top: 1px;
  margin-right: 1px;
}

.Tooltip__close:hover,
.Tooltip__close.focus-visible {
  color: #3b474d;
}

.Tooltip--top,
.Tooltip--top-left,
.Tooltip--top-right,
.Tooltip--center,
.Tooltip--left,
.Tooltip--right {
  box-shadow: 0 0.75rem 2.5rem -1rem rgba(0, 0, 0, 0.25);
}

.Tooltip--top .Tooltip__close,
.Tooltip--top-left .Tooltip__close,
.Tooltip--top-right .Tooltip__close,
.Tooltip--center .Tooltip__close,
.Tooltip--left .Tooltip__close,
.Tooltip--right .Tooltip__close {
  margin-top: 0.25rem;
}

.Tooltip__headingContainer {
  font-weight: 600;
  overflow-wrap: break-word;
  padding-bottom: 0.5rem;
}

.Tooltip__bodyContainer {
  overflow-wrap: break-word;
  padding-bottom: 0.5rem;
}

.Tooltip__gotItContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.Tooltip__gotIt {
  font-weight: 600;
  text-decoration: underline;
  color: #596b74;
}

.Tooltip__gotIt:hover,
.Tooltip__gotIt.focus-visible {
  color: #3b474d;
}

.Tooltip--visible {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.Tooltip--top,
.Tooltip--top-left,
.Tooltip--top-right,
.Tooltip--center {
  bottom: 0.5rem;
}

.Tooltip--bottom,
.Tooltip--bottom-left,
.Tooltip--bottom-right {
  top: 0.5rem;
}

.Tooltip--right {
  left: 0.5rem;
}

.Tooltip--left {
  right: 0.5rem;
}

/**
 * Arrow / direction / anchor
 */

.Tooltip__arrow {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
  border-top-width: 1px;
  border-left-width: 1px;
}

.Tooltip__arrow--top,
.Tooltip__arrow--top-left,
.Tooltip__arrow--top-right,
.Tooltip__arrow--center {
  top: 100%;
  margin-top: -0.5rem;
  transform: rotate(-135deg);
}

.Tooltip__arrow--bottom,
.Tooltip__arrow--bottom-left,
.Tooltip__arrow--bottom-right {
  bottom: 100%;
  margin-bottom: -0.5rem;
  transform: rotate(45deg);
}

.Tooltip__arrow--top-left,
.Tooltip__arrow--bottom-left {
  left: 1.5rem;
}

.Tooltip__arrow--top-right,
.Tooltip__arrow--bottom-right {
  left: calc(100% - 1rem - 1.5rem);
}

.Tooltip__arrow--top,
.Tooltip__arrow--bottom,
.Tooltip__arrow--center {
  left: calc(50% - 1rem / 2);
}

.Tooltip__arrow--right,
.Tooltip__arrow--left {
  top: calc(50% - 1rem / 2);
}

.Tooltip__arrow--right {
  right: 100%;
  margin-right: -0.5rem;
  transform: rotate(-45deg);
}

.Tooltip__arrow--left {
  left: 100%;
  margin-left: -0.5rem;
  transform: rotate(135deg);
}

/**
 * Border
 */

.Tooltip__border {
  position: absolute;
  width: 100%;
  height: 0.25rem;
  background-color: #7141d8;
  left: 0;
}

.Tooltip__border--top,
.Tooltip__border--top-left,
.Tooltip__border--top-right,
.Tooltip__border--center,
.Tooltip__border--left,
.Tooltip__border--right {
  top: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.Tooltip__border--bottom,
.Tooltip__border--bottom-left,
.Tooltip__border--bottom-right {
  bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/**
 * Content - make sure it overlaps the arrow
 */

.Tooltip__content {
  font-size: 0.75rem;
  position: relative;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: #ffffff;
  border-radius: 0.25rem;
}
