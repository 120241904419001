.LoadingSpinner {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

/**
 * Sizes
 */

.LoadingSpinner--lg {
  height: 8rem;
  width: 8rem;
}

.LoadingSpinner--md {
  height: 4rem;
  width: 4rem;
}

/**
 * SVG setup - correctly align progress stroke to 12 o'clock
 */

.LoadingSpinner__svg {
  transform: rotate(270deg);
  height: 100%;
  width: 100%;
}

/**
 * Colors
 */

.LoadingSpinner__background {
  stroke-width: 3;
  stroke: #f4f4f4;
  fill: none;
}

.LoadingSpinner__fill {
  stroke: currentColor;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 1s;
  stroke-width: 3;
  stroke-linecap: round;
  fill: none;
}

.LoadingSpinner__fill--red {
  stroke: #d73955;
}

.LoadingSpinner__fill--green {
  stroke: #a8d763;
}

.LoadingSpinner__fill--white {
  stroke: #ffffff;
}

.LoadingSpinner__background--white {
  stroke: #dde2e6;
}

/**
 * Loading percentage text
 */

.LoadingSpinner__percent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Noto Sans, sans-serif;
  font-weight: 300;
}

.LoadingSpinner__percent--lg {
  font-size: 2rem;
}

.LoadingSpinner__percent--md {
  font-size: 1rem;
}

.LoadingSpinner__percent--red {
  color: #d73955;
}

.LoadingSpinner__percent--green {
  color: #a8d763;
}

/**
 * Small size one-offs
 * Percentage text sits to the right of the spinner
 * instead of inside, due to size considerations
 */

.LoadingSpinner--sm {
  height: 1rem;
  width: auto;
}

.LoadingSpinner__svg--sm {
  width: 1rem;
}

.LoadingSpinner__percent--sm {
  font-size: 0.75rem;
  position: static;
  margin-left: 0.25rem;
  display: inline-block;
  vertical-align: top;
  line-height: 1.25;
}

.LoadingSpinner__background--sm,
.LoadingSpinner__fill--sm {
  stroke-width: 6;
}

/**
 * Infinite/Indeterminate animation
 */

.LoadingSpinner__svg--animated {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
}

.LoadingSpinner__fill--animated {
  -webkit-animation: dash 1.5s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite;
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
