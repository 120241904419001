.Header {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 50;
}

.Header + .Main {
  margin-top: 6.5rem;
}

.Header__topBar {
  display: flex;
  min-height: 45px;
  background-image: radial-gradient(circle at -8% 77%, #4e23ab, #8a239c 49%, #c64876 100%);
}

.Header__topBarContainer {
  display: flex;
  flex: 1 1 0%;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1280px) {
  .Header__topBarContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
