.Trend {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  border-radius: 9999px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.25;
  border-width: 1px;
}

.Trend__arrow {
  margin-right: 0.25rem;
  height: 0.5rem;
  width: 0.5rem;
}

.Trend--positive {
  border-color: #8bca2f;
}

.Trend--positive .Trend__label,
.Trend--positive .Trend__arrow {
  color: #8bca2f;
}

.Trend--negative {
  border-color: #de1515;
}

.Trend--negative .Trend__label,
.Trend--negative .Trend__arrow {
  color: #de1515;
}

.Trend--neutral {
  border-color: #788f9c;
}

.Trend--neutral .Trend__label,
.Trend--neutral .Trend__arrow {
  color: #788f9c;
}
