.TextTooltip {
  cursor: pointer;
}

.TextTooltip .TextTooltip--text {
  text-decoration: underline;
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
  -webkit-text-decoration-color: #7141d8;
          text-decoration-color: #7141d8;
  text-underline-offset: 0.2rem;
  width: auto;
  display: inline-block;
}
