.TextInput {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.TextInput__element {
  transition-timing-function: cubic-bezier(0.45, 0, 1, 1);
  transition-duration: 67ms, 133ms;
  transition-property: all, background-color;
  display: flex;
  caret-color: #596b74;
  background-color: #ffffff;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: #788f9c;
  font-family: Noto Sans, sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  color: #3b474d;
  width: 100%;
  height: 2rem;
  overflow: hidden;
  box-sizing: border-box;
}

.TextInput__element--small {
  height: 1.5rem;
  font-size: 0.75rem;
}

.TextInput__element--large {
  height: 3rem;
}

.TextInput__element::-webkit-input-placeholder {
  color: #788f9c;
}

.TextInput__element::-moz-placeholder {
  color: #788f9c;
}

.TextInput__element:-ms-input-placeholder {
  color: #788f9c;
}

.TextInput__element::-ms-input-placeholder {
  color: #788f9c;
}

.TextInput__element::placeholder {
  color: #788f9c;
}

.TextInput__element:focus:not(.AdvancedSearchBar__input) {
  border-color: #6d98ed;
  outline: 0;
}

.TextInput__element--disabled,
.TextInput__element--disabled:focus {
  cursor: not-allowed;
  border-width: 1px;
  border-color: #dde2e6;
  color: #99abb4;
  background-color: #f4f4f4;
}

.TextInput__element .TextInput__container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  height: 100%;
}

.TextInput__element .TextInput__container--base {
  flex-grow: 1;
}

.TextInput__element .TextInput__container--large {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.TextInput__element .TextInput__prefixWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-color: #3b474d;
  border-right-width: 1px;
}

.TextInput__element .TextInput__prefixWrapper--disabled {
  border-color: #bbc7cd;
}

.TextInput__element .TextInput__prefix {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TextInput__element .TextInput__prefix--small {
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.TextInput__element .TextInput__prefix--large {
  min-width: 3rem;
  font-size: 2rem;
}

.TextInput__actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  margin: 0;
  border-radius: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.TextInput__actionButton--large {
  padding-left: 1rem;
  padding-right: 1rem;
}

.TextInput__actionButton--large .TextInput__actionButton--icon {
  width: 1.5rem;
  height: 1.5rem;
}

.TextInput__actionButton--small {
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  min-height: 100%;
}

.TextInput__actionButton--small .TextInput__actionButton--icon {
  width: 0.75rem;
  height: 0.75rem;
}

.TextInput__helperWrapper {
  display: flex;
  align-items: flex-start;
}

.TextInput__helperWrapper:not(:empty) {
  margin-top: 0.25rem;
}

.TextInput__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

/**
 * Validation & Errors
 */

.TextInput__element--valid {
  border-color: #6d98ed;
}

.TextInput__icon--valid {
  color: #8bca2f;
}

.TextInput__element--error {
  border-color: #b21111;
}

.TextInput__icon--error {
  color: #b21111;
}

.InputLabel__wrapper {
  display: inline-block;
}
