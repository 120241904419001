.RadioButtons__wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: block;
}

.RadioButtons__wrapper--inline {
  display: inline-flex;
  margin-right: 1rem;
}

.RadioButtons__legend {
  display: block;
  margin-bottom: 0.25rem;
  font-family: Noto Sans, sans-serif;
  line-height: 1.25;
  font-size: 1rem;
  color: #3b474d;
}

.RadioButtons__legend--hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.RadioButtons__legend__asterisk::after {
  content: '*';
  color: #b21111;
  margin-left: 0.25rem;
}

.RadioButtons__legend--hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.RadioButtons__legend--asterisk::after {
  content: '*';
  color: #b21111;
  margin-left: 0.25rem;
}

.RadioButtons__wrapper--IconRadio {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: -0.5rem;
}

@media (min-width: 768px) {
  .RadioButtons__wrapper--IconRadio {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.RadioButtons__wrapper--IconRadio .IconRadio {
  margin: 0.5rem;
}

.RadioButtons__wrapper--NestedRadio .RadioButtons__wrapper .NestedRadio {
  margin-bottom: 1.5rem;
}

.RadioButtons__wrapper--NestedRadio .RadioButtons__wrapper:last-child .NestedRadio {
  margin-bottom: 0;
}

.RadioButtons__helperWrapper {
  display: flex;
  align-items: flex-start;
}

.RadioButtons__helperWrapper:not(:empty) {
  margin-top: 0.25rem;
}

.RadioButtons__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

/**
 * Validation & Errors
 */

.RadioButtons__icon--valid {
  color: #8bca2f;
}

.RadioButtons__icon--error {
  color: #b21111;
}
