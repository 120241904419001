.PageTitle {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

@media (min-width: 768px) {
  .PageTitle {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 1280px) {
  .PageTitle {
    margin-top: 0;
    margin-left: 0;
  }
}
