.HelperText {
  display: block;
  font-family: Noto Sans, sans-serif;
  font-size: 0.75rem;
  color: #3b474d;
  line-height: 1.4;
}

.HelperText--large {
  font-size: 0.875rem;
}

.HelperText--error {
  color: #b21111;
}
