.DataTableSelection {
  margin-top: 0.75rem;
  font-family: Noto Sans, sans-serif;
  font-size: 0.875rem;
}

.DataTableSelection .Button {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
}

.DataTableSelection + .ScrollWrapper > .DataTable,
.DataTableSelection + .DataTable {
  margin-top: 0.25rem;
}

.DataTable__Checkbox {
  position: relative;
  top: 0.15rem;
  left: -1rem;
  margin-right: 1rem;
}

.Table__TableRow--condensed .DataTable__Checkbox {
  left: 0;
}

.Table__head .DataTable__Checkbox {
  top: 0;
}
