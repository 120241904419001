.AdvancedSwitcherHeader {
  align-items: center;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
  background-color: #ffffff;
  box-shadow: 0 -0.001rem 0.25rem 0.125rem rgba(0, 0, 0, 0.3);
}

.AdvancedSwitcherHeader .AdvancedSwithcerHeader--search {
  width: 75%;
}

.AdvancedSwitcherHeader .AdvancedSwitcherHeader__searchBtn {
  height: 1rem;
  padding: 0.5rem;
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.AdvancedSwitcherHeader .AdvancedSwitcherHeader__searchBtn .Icon {
  color: #ffffff;
}

.AdvancedSwitcherHeader span {
  font-size: 0.75rem;
}

.AdvancedSwitcherHeader .Icon,
.AdvancedSwitcherHeader span {
  color: #596b74;
}

.AdvancedSwitcherHeader .AdvancedSwithcerHeader--search__noBorder {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.AdvancedSwitcherHeader .SearchBar__element:not(.SearchBar__element--disabled)::-webkit-input-placeholder {
  color: #788f9c;
}

.AdvancedSwitcherHeader .SearchBar__element:not(.SearchBar__element--disabled)::-moz-placeholder {
  color: #788f9c;
}

.AdvancedSwitcherHeader .SearchBar__element:not(.SearchBar__element--disabled):-ms-input-placeholder {
  color: #788f9c;
}

.AdvancedSwitcherHeader .SearchBar__element:not(.SearchBar__element--disabled)::-ms-input-placeholder {
  color: #788f9c;
}

.AdvancedSwitcherHeader .SearchBar__element:not(.SearchBar__element--disabled)::placeholder {
  color: #788f9c;
}

.AdvancedSwitcherHeader__closeBtn {
  padding-right: 0.25rem;
}
