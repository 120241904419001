.RadioButtonInput {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.RadioButtonInput.focus-visible + .RadioButton {
  /* Non-webkit focus styles - FF, IE, etc. */
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .RadioButtonInput.focus-visible + .RadioButton {
    /* Webkit native focus styles */
    outline: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.RadioButton {
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 67ms;
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: Noto Sans, sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  color: #596b74;
  cursor: pointer;
}

.RadioButton--hasAsterisk::after {
  content: '*';
  color: #b21111;
  margin-left: 0.25rem;
}

.RadioButton__radio {
  margin-top: 0.25rem;
  align-self: flex-start;
}

.RadioButton__helperText {
  font-size: 0.75rem;
}

.RadioButton__canvas {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
}

.RadioButton__background {
  fill: currentColor;
  color: #ffffff;
}

.RadioButton__circle {
  transition-timing-function: cubic-bezier(0.45, 0, 1, 1);
  transition-duration: 133ms;
  transition-property: fill;
  fill: currentColor;
  color: #ffffff;
}

.RadioButton__border {
  fill: currentColor;
  color: #788f9c;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 200ms;
  transition-property: fill;
}

.RadioButton:hover .RadioButton__border {
  fill: currentColor;
  color: #6d98ed;
}

/**
 * Checked variant
 */

.RadioButton--checked {
  color: #596b74;
}

.RadioButton--checked .RadioButton__circle,
.RadioButton--checked .Radiobutton__icon,
.RadioButton--checked .RadioButton__border {
  fill: currentColor;
  color: #6d98ed;
}

.RadioButton--checked:hover .RadioButton__border,
.RadioButton--checked:hover .RadioButton__circle {
  fill: currentColor;
  color: #7141d8;
}

/**
 * Disabled variant
 */

.RadioButton--disabled {
  color: #bbc7cd;
  cursor: not-allowed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.RadioButton--disabled .RadioButton__border {
  fill: currentColor;
  color: #dde2e6;
}

.RadioButton--disabled:hover .RadioButton__border {
  fill: currentColor;
  color: #dde2e6;
}

.RadioButton--disabled.RadioButton--checked .RadioButton__background {
  fill: currentColor;
}

.RadioButton--disabled.RadioButton--checked .RadioButton__circle,
.RadioButton--disabled.RadioButton--checked .RadioButton__border {
  fill: currentColor;
  color: #99abb4;
}

/** Variant with icon
 *
 */

.RadioButton__iconVariant .RadioButton__canvas {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
}

.RadioButton__iconVariant.RadioButton--checked .RadioButton__border {
  color: #8bca2f;
}

.RadioButton__iconVariant.RadioButton--checked .Radiobutton__icon {
  color: #8bca2f;
  transform: scale(1.2);
}

.RadioButton--border {
  border-width: 1px;
  border-radius: 0.25rem;
  padding: 0.75rem;
  border-color: #788f9c;
}

.RadioButton__helperWrapper {
  display: flex;
  align-items: flex-start;
}

.RadioButton__helperWrapper:not(:empty) {
  margin-top: 0.25rem;
}

.RadioButton__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

/**
 * Validation & Errors
 */

.RadioButton--valid {
  border-color: #6d98ed;
}

.RadioButton__icon--valid {
  color: #8bca2f;
}
