.ListCard {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding-left: 0.75rem;
  padding-bottom: 2.25rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
}

.ListCard h3 {
  font-size: 1.5rem;
  color: #7141d8;
}

.ListCard h3:hover {
  color: #4e23ab;
}

@media (min-width: 768px) {
  .ListCard {
    padding-left: 2.25rem;
    padding-top: 1.5rem;
    padding-right: 1.5rem;
  }
}

.ListCard--border {
  border-color: #7141d8;
  border-top-width: 8px;
  border-left-width: 0;
  border-radius: 0.25rem;
}

@media (min-width: 768px) {
  .ListCard--border {
    border-left-width: 8px;
    border-top-width: 0;
  }
}

.ListCard--logo {
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 0.25rem;
  max-width: none;
  width: 2.5rem;
  height: 2.5rem;
}

@media (min-width: 768px) {
  .ListCard--logo {
    width: 5rem;
    height: 5rem;
  }
}

.ListCard--subTitle {
  margin: 0;
  padding: 0;
  text-align: left;
  font-weight: 400;
}

.ListCard--title {
  margin: 0;
  padding: 0;
  text-align: left;
}

.ListCard .ThreeDotMenu__trigger {
  width: 1.5rem;
  height: 1.5rem;
}

.ListCard .ThreeDotMenu__trigger .Icon {
  width: 1.5rem;
  height: 1.5rem;
}
