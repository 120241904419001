.IconRadio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin: 1px;
  cursor: pointer;
  background-color: #ffffff;
  height: 10rem;
  width: 12rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 1px #788f9c;
}

.IconRadio--checked {
  box-shadow: 0 0 0 2px #7141d8;
}

.IconRadio__labelContainer {
  height: 3rem;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.IconRadio__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}

.IconRadioInput {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  margin: 0.5rem;
}

.IconRadioInput.focus-visible + .Radio {
  /* Non-webkit focus styles - FF, IE, etc. */
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .IconRadioInput.focus-visible + .Radio {
    /* Webkit native focus styles */
    outline: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.IconRadio__icon {
  height: 4rem;
  width: 4rem;
  color: #7141d8;
}

.IconRadio__TooltipWrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.IconRadio__TooltipIconWrapper {
  padding-right: 0.5rem;
  padding-top: 1px;
}

.IconRadio__TooltipIcon {
  color: #7141d8;
  width: 1rem;
  height: 1rem;
}
