.main {
	max-width: 700px;
	text-align: center;
	margin: 0 auto;
	padding: 3%;
	font-family: Arial, sans-serif;
}

.logo-wrapper {
	padding: 20px 0;
}

.logo {
	display: inline;
	max-width: 100%;
}

.button-link {
	background-color: #861e8c;
	border: 2px solid #861e8c;
	border-radius: 25px;
	color: #fff !important;
	font-weight: 600;
	letter-spacing: 0.3px;
	padding: 10px 30px;
	transition: background-color 0.5s;
	text-decoration: none !important;
	display: inline-block;
	margin: 20px 0;
}

.button-link-disabled {
	pointer-events: none;
	cursor: default;
	background-color: #5f5f5f;
	border: 2px solid #5f5f5f;
	border-radius: 25px;
	color: #fff !important;
	font-weight: 600;
	letter-spacing: 0.3px;
	padding: 10px 30px;
	transition: background-color 0.5s;
	text-decoration: none !important;
	display: inline-block;
	margin: 20px 0;
}

.policy-url {
	color: #0000ee;
}
