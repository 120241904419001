.AdvancedSwitcherGroup .AdvancedSwitcherGroup__head {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.AdvancedSwitcherGroup .AdvancedSwitcherGroup__count {
  line-height: 0.4;
}

.AdvancedSwitcherGroup .AdvancedSwitcherGroup__text {
  font-weight: 400;
  color: #bbc7cd;
  font-size: 0.75rem;
  margin-right: 0.25rem;
}

.AdvancedSwitcherGroup .AdvancedSwitcherGroup__radioGroup {
  padding-left: 0.25rem;
}

.AdvancedSwitcherGroup .AdvancedSwitcherGroup__wrapper h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 18rem;
}

.AdvancedSwitcherGroup .AdvancedSwitcherGroup__buttonIcon {
  color: #99abb4;
  margin-right: 1.5rem;
}

.AdvancedSwitcherGroup .AdvancedSwitcherGroup__buttonIcon:hover {
  color: #788f9c;
}
