.Alert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  font-family: Noto Sans, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: transparent;
}

.Alert--success {
  color: #456418;
  border-color: #456418;
  background-color: #e7f4d5;
}

.Alert--success .Alert__icon,
.Alert--success .Alert__dismiss {
  color: #456418;
}

.Alert--info {
  color: #123c90;
  border-color: #123c90;
  background-color: #edf2fc;
}

.Alert--info .Alert__icon,
.Alert--info .Alert__dismiss {
  color: #123c90;
}

.Alert--warning {
  color: #985f00;
  border-color: #985f00;
  background-color: #ffebcb;
}

.Alert--warning .Alert__icon,
.Alert--warning .Alert__dismiss {
  color: #985f00;
}

.Alert--danger {
  color: #b21111;
  border-color: #b21111;
  background-color: #fcecec;
}

.Alert--danger .Alert__icon,
.Alert--danger .Alert__dismiss {
  color: #b21111;
}

.Alert__content {
  flex-grow: 1;
}

.Alert__icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.Alert__dismiss {
  flex-shrink: 0;
  align-self: flex-start;
  margin-left: 1rem;
  cursor: pointer;
  color: inherit;
}
