/**
 * Drag preview
 */

.DataTableDragger {
  pointer-events: none;
  z-index: 50;
  position: fixed;
  padding: 0.5rem;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  box-sizing: content-box;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  visibility: hidden;
  opacity: 0;
  transition-property: opacity, visibility;
  transition-duration: 67ms;
  transition-timing-function: linear;
}

.DataTableDragger--visible {
  visibility: visible;
  opacity: 1;
}

.DataTableDragger .DataTable {
  box-sizing: border-box;
  margin: 0;
}

.DataTableDragger .DataTable__dragAnchor {
  color: #6d98ed;
}

.DataTableDragger .Table__TableHeader {
  text-align: center;
  border-color: #6d98ed;
  background-color: #f4f4f4;
}

.DataTableDragger .Table__TableHeader::after {
  content: none;
}

.DataTableDragger .Table__head .Table__TableRow {
  border-color: #6d98ed;
  border-bottom-width: 2px;
}

.DataTableDragger .Table__TableRow:not(:last-child) {
  border-color: #6d98ed;
}

.DataTableDragger .Table__TableFooter {
  border-color: #6d98ed;
  border-top-width: 2px;
}

/**
 * Drag anchor button
 */

.DataTable__dragAnchor {
  padding: 0.1rem;
}

.DataTable__dragAnchor:hover {
  cursor: move;
}

.DataTable__dragAnchor::before {
  /* stylelint-disable unit-blacklist */
  content: '';
  display: block;
  background-color: currentColor;
  background-image: linear-gradient(#ffffff 1px, transparent 1px), linear-gradient(90deg, #ffffff 1px, transparent 1px);
  background-size: 4px 4px, 4px 4px;
  background-position: -1px -1px, -1px -1px;
  border: 1px solid #ffffff;
  width: 9px;
  height: 14px;
  /* stylelint-enable unit-blacklist */
}

/**
 * DataTable drag modifiers
 */

.DataTable--dragging {
  cursor: move;
  /**
   * Column drag states
   */
}

.DataTable--dragging .DataTable__headerButton,
.DataTable--dragging .DataTable__columnIndicator {
  pointer-events: none;
}

.DataTable--dragging .Table__TableData,
.DataTable--dragging .Table__TableHeader {
  /**
     * Fade out column being dragged
     */
  /**
     * Column(s) being dragged over - blue line indicator to show where column will end up
     * @see DataTable.less for .DataTable__columnIndicator setup
     */
  /**
     * Shows a dragging indicator _on_ the first column _for_ the second column,
     * when the second column is being dragged over. This is a z-index workaround
     * for when the DataTable has both a pinnedFirstColumn and a pinnedHeader.
     */
}

.DataTable--dragging .Table__TableData--dragged,
.DataTable--dragging .Table__TableHeader--dragged {
  opacity: 0.25;
}

.DataTable--dragging .Table__TableData--dragged .DataTable__dragAnchor,
.DataTable--dragging .Table__TableHeader--dragged .DataTable__dragAnchor {
  color: #99abb4;
}

.DataTable--dragging .Table__TableData--draggingOver .DataTable__columnIndicator,
.DataTable--dragging .Table__TableHeader--draggingOver .DataTable__columnIndicator {
  right: auto;
  left: -3px;
  background-color: #6d98ed;
  opacity: 1;
}

.DataTable--dragging .Table__TableData--draggingNextToFirst::after,
.DataTable--dragging .Table__TableHeader--draggingNextToFirst::after,
.DataTable--dragging .Table__TableData--draggingNextToFirst[scope="row"]::after,
.DataTable--dragging .Table__TableHeader--draggingNextToFirst[scope="row"]::after {
  background-color: #936fe1;
  border-color: #936fe1;
}
