/**
 * Heading, Tags, and toggle Button
 */

.Filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.Filter .Heading {
  margin-right: 0.75rem;
  line-height: 1;
}

/**
 * Popover and Form
 */

.FilterPopover {
  width: 16rem;
  /**
   * Search/filter
   */
  /**
   * Accordions
   */
  /**
   * Form inputs
   */
}

.FilterPopover .Popover__arrow {
  left: 0.9rem;
}

.FilterPopover .Popover__content {
  padding: 0;
}

.FilterPopover .SearchBar {
  margin-top: 0;
  margin-bottom: 0;
  border-bottom-width: 1px;
}

.FilterPopover .SearchBar__element {
  height: 2.5rem;
  font-size: 0.875rem;
  border-width: 0;
}

.FilterPopover .SearchBar__element:focus {
  background-color: transparent;
}

.FilterPopover .SearchBar__icon {
  transition-property: color;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
}

.FilterPopover .SearchBar:focus-within .SearchBar__icon {
  color: #99abb4;
}

.FilterPopover .Accordion {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}

.FilterPopover .Accordion + .Accordion {
  border-top-width: 1px;
  margin-top: 0;
}

.FilterPopover .Accordion__toggle {
  font-size: 1rem;
  background-color: transparent;
}

.FilterPopover .Accordion[open] {
  padding-bottom: 0.5rem;
}

.FilterPopover .Accordion__item {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  line-height: 1;
}

.FilterPopover .Accordion__item:hover {
  background-color: transparent;
}

.FilterPopover .Accordion__wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

.FilterPopover .Accordion__wrapper .Accordion__toggle {
  padding: 0.25rem;
  font-size: 0.875rem;
}

.FilterPopover .Accordion__wrapper .Accordion__icon {
  font-size: 50%;
}

.FilterPopover .InputLabel,
.FilterPopover .CheckboxGroup__legend,
.FilterPopover .RadioButtons__legend,
.FilterPopover .Switch {
  font-size: 0.875rem;
  font-weight: 300;
}

.FilterPopover .Checkbox,
.FilterPopover .RadioButton,
.FilterPopover .TextInput__element,
.FilterPopover .TextArea__element {
  font-size: 0.75rem;
}

.FilterPopover .TextInput__element,
.FilterPopover .TextArea__element {
  border-radius: 0;
}

.FilterPopover .TextInput,
.FilterPopover .TextArea,
.FilterPopover .RadioButtons,
.FilterPopover .CheckboxGroup,
.FilterPopover .SelectWrapper {
  margin-top: 0;
  margin-bottom: 0;
}

.FilterPopover .Checkbox {
  display: flex;
  line-height: 1.25;
}

.FilterPopover .CheckboxGroup__wrapper {
  margin-bottom: 0.5rem;
}

.FilterPopover .CheckboxGroup__wrapper:last-child {
  margin-bottom: 0.25rem;
}

.FilterPopover__content {
  max-height: calc(100vh - 6rem);
  overflow: auto;
}

.FilterPopover__apply {
  padding: 0.5rem;
  border-top-width: 1px;
}

/**
 * Filter Form - searching state
 */

.FilterPopover__searching .Accordion__toggle {
  pointer-events: none;
}

.FilterPopover__searching .Accordion__icon {
  display: none;
}

.FilterPopover__searching .Accordion__heading {
  padding-left: 0;
}

.FilterPopover__noResults {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  text-align: center;
  color: #596b74;
}

.FilterTag__text {
  color: #596b74;
}
