.Wizard {
  font-family: Noto Sans, sans-serif;
  font-size: 1rem;
}

.Wizard__stage {
  margin-top: 2rem;
  margin-bottom: 1.25rem;
}

.Wizard__footer {
  display: flex;
}

.Wizard__footer .prevButton {
  margin-right: 3.75rem;
}
