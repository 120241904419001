.AdvancedFilterGroup {
  font-family: Noto Sans, sans-serif;
  /**
   * Form inputs
   */
}

.AdvancedFilterGroup + .AdvancedFilterGroup {
  border-top-width: 1px;
  border-color: #dde2e6;
  border-style: solid;
  margin-top: -3px;
}

.AdvancedFilterGroup .AdvancedFilterGroup_ClearFilter {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.75rem;
}

.AdvancedFilterGroup .Tag {
  height: auto;
}

.AdvancedFilterGroup .InputLabel,
.AdvancedFilterGroup .CheckboxGroup__legend,
.AdvancedFilterGroup .RadioButtons__legend,
.AdvancedFilterGroup .Switch {
  font-size: 0.875rem;
  font-weight: 300;
}

.AdvancedFilterGroup .MultiSelectBrick {
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.AdvancedFilterGroup .Checkbox,
.AdvancedFilterGroup .RadioButton,
.AdvancedFilterGroup .TextInput__element,
.AdvancedFilterGroup .TextArea__element {
  font-size: 0.75rem;
}

.AdvancedFilterGroup .TextInput,
.AdvancedFilterGroup .TextArea,
.AdvancedFilterGroup .RadioButtons,
.AdvancedFilterGroup .CheckboxGroup,
.AdvancedFilterGroup .MultiSelectBrickGroup,
.AdvancedFilterGroup .DatePicker,
.AdvancedFilterGroup .SelectWrapper,
.AdvancedFilterGroup .AdvancedSelectWrapper {
  margin-top: 0;
  margin-bottom: 0;
}

.AdvancedFilterGroup .Checkbox {
  display: flex;
  line-height: 1.25;
}

.AdvancedFilterGroup .CheckboxGroup__wrapper {
  margin-bottom: 0.5rem;
}

.AdvancedFilterGroup .CheckboxGroup__wrapper:last-child {
  margin-bottom: 0.25rem;
}

.AdvancedFilterGroup .RadioButtons__wrapper {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

/**
 * Summary / toggle / heading
 */

.AdvancedFilterGroup__toggle {
  padding-bottom: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 0.25rem;
  list-style: none;
}

.AdvancedFilterGroup__toggle::-webkit-details-marker {
  display: none;
}

.AdvancedFilterGroup__icon {
  color: #7141d8;
  transition-property: transform;
  transition-duration: 200ms;
  width: 1.5rem;
  height: 1.5rem;
}

.AdvancedFilterGroup__icon::before {
  position: static;
}

.AdvancedFilterGroup__icon--open {
  transform: rotate(180deg);
}

.AdvancedFilterGroup__heading {
  padding-left: 0.5rem;
}

.AdvancedFilterGroup__headingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdvancedFilterGroup__headingContainer {
  display: flex;
  align-items: center;
}

/**
 * Details / list
 */

.AdvancedFilterGroup__list {
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-property: height;
}

.AdvancedFilterGroup__item {
  cursor: pointer;
  padding-bottom: 2rem;
  padding-left: 1.5rem;
}
