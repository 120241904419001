.CardBanner {
  display: flex;
  justify-content: space-between;
  height: 5rem;
  font-size: 1.25rem;
  font-weight: 700;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 1rem;
  align-items: center;
  border-bottom-width: 1px;
  border-color: #dde2e6;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.CardBanner .CardBanner--title {
  width: 66.666667%;
  padding-left: 1rem;
}

.CardBanner .Image {
  width: 33.333333%;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  width: 100%;
  max-width: 8rem;
  height: 2.5rem;
  align-self: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-right: 1rem;
}

.CardBanner--success {
  border-top-width: 8px;
  border-top-color: #8bca2f;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.CardBanner--info {
  border-top-width: 8px;
  border-top-color: #baccf3;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.CardBanner--warning {
  border-top-width: 8px;
  border-top-color: #ffcf80;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.CardBanner--danger {
  border-top-width: 8px;
  border-top-color: #eb9caa;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.CardBanner--secondary {
  border-top-width: 8px;
  border-top-color: #b8a0eb;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.CardBanner--active {
  border-color: #6d98ed;
  border-width: 2px;
  border-radius: 0.25rem;
}
