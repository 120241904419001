.Breadcrumbs {
  line-height: normal;
  font-size: 1rem;
  display: flex;
  width: 100%;
  align-items: center;
}

.Breadcrumbs__textlink:last-child {
  color: #788f9c;
  pointer-events: none;
  background-image: none;
}

.Breadcrumbs__separator {
  width: 1rem;
  height: 1rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-top: -1px;
  color: #788f9c;
}

.Breadcrumbs--small {
  font-size: 0.75rem;
}

.Breadcrumbs--small .Breadcrumbs__separator {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
