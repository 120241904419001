/**
 * Skip to main content button
 */

.SkipToMain {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.SkipToMain:focus {
  position: unset;
  width: unset;
  height: unset;
  margin: unset;
  padding: unset;
  border: unset;
  overflow: unset;
  clip: unset;
  z-index: 100;
  position: absolute;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: Noto Sans, sans-serif;
  font-size: 1rem;
  text-decoration: none;
  color: #ffffff;
  background-color: #7141d8;
  border-radius: 0.25rem;
}

/**
 * Main container
 */

.Main {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
}

/**
 * Main & BackTo rounding interaction
 */

.Main > .BackTo:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
