.CalendarMonth_caption strong {
  font-weight: 400;
  font-size: 1rem;
}

/**
 * Hide default react-dates navigation arrows
 */

.DayPickerNavigation__horizontal {
  display: none;
}

/**
 * Building our own custom month+year navigation arrows
 */

.DatePicker__nav,
.DatePicker__monthNav,
.DatePicker__yearNav {
  display: flex;
  color: #596b74;
}

.DatePicker__nav {
  justify-content: center;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.25rem;
}

.DatePicker__monthTitle {
  min-width: 6rem;
}

.DatePicker__navArrow {
  width: 1.5rem;
  height: 1.5rem;
}

.DatePicker__navArrow::before {
  content: '';
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.25rem;
  border-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-color: #596b74;
}

.DatePicker__navArrow--previous::before {
  transform: rotate(-135deg);
}

.DatePicker__navArrow--next::before {
  transform: rotate(45deg);
}
