.TableWrapper {
  border-width: 1px;
  border-radius: 0.25rem;
  background: linear-gradient(0deg, #ffffff 0%, #f4f4f4 67%);
}

.Table {
  width: 100%;
  font-family: Noto Sans, sans-serif;
  font-size: 0.875rem;
  color: #596b74;
}

.Table--noScroll {
  background-color: #ffffff;
}

/**
 * Child elements
 */

.Table__head {
  vertical-align: bottom;
  background-color: #f4f4f4;
}

.Table__head .Table__TableRow:last-child {
  border-bottom-width: 1px;
  border-color: #dde2e6;
}

.Table__foot {
  border: inherit;
  background-color: #ffffff;
}

.Table__foot .Table__TableFooter:first-child {
  border-top-width: 1px;
  border-color: #dde2e6;
}

.Table__body {
  border: inherit;
  background-color: #ffffff;
}

/**
 * Pinned modifiers/elements
 */

/**
 * Warning: Here be CSS dragons. This is not a file for the faint of heart.
 *
 * position: sticky is a relatively new CSS property that comes with plenty
 * plenty of cross-browser issues and required workarounds. Most of the workarounds
 * also stem from the weird interaction of tables and position sticky combined.
 *
 * @see https://bugs.chromium.org/p/chromium/issues/detail?id=702927
 * @see https://github.com/w3c/csswg-drafts/issues/3136
 */

/**
 * Gradient & variable set-up
 */

/**
 * Shadow gradients
 *
 * When within a ScrollWrapper, these shadows should take on the same behavior as
 * their corresponding indicator. E.g., the header shadow should only show when
 * ScrollWrapper--scrollTop is present and the user can scroll back up, and the
 * pinned column shadow should only show when the user can scroll to the left.
 *
 * When outside a ScrollWrapper (canScroll=false), we should default to just showing
 * the shadow gradients, so that there's some indication of special/pinned behavior.
 */

/**
* Pinned column
*/

/**
 * Pinned first column
 */

.ScrollWrapper:not(.ScrollWrapper--scrollLeft) > .Table--pinnedFirstColumn .Table__TableRow > :first-child::before {
  content: none;
}

.Table--pinnedFirstColumn .Table__TableRow > :first-child {
  z-index: 20;
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  background-color: #ffffff;
  background-clip: padding-box;
}

.Table--pinnedFirstColumn .Table__TableRow > :first-child::before {
  pointer-events: none;
  content: '';
  position: absolute;
  left: 100%;
  bottom: 0;
  top: -1px;
  width: 16px;
  background-image: linear-gradient(to right, rgba(75, 75, 75, 0.1), rgba(255, 255, 255, 0) 100%);
  background-repeat: no-repeat;
}

.Table--pinnedFirstColumn > :first-child > .Table__TableRow:first-child > :first-child::before {
  background-image: radial-gradient(ellipse farthest-side at bottom left, rgba(75, 75, 75, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

.Table--pinnedFirstColumn > :last-child > .Table__TableRow:last-child > :first-child::before {
  background-image: radial-gradient(ellipse farthest-side at top left, rgba(75, 75, 75, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

.Table--pinnedFirstColumn .Table__TableRow--selected > :first-child {
  background-color: #edf2fc;
}

.Table--pinnedFirstColumn .Table__TableRow--hoverable:hover > :first-child {
  background-color: #f9eef2;
  box-shadow: inset 0 -2px 0 -1px #dde2e6;
}

/**
 * Pinned last column
 */

.Table--pinnedThreeDotMenuOptions .Table__TableRow > :last-child {
  z-index: 20;
  position: -webkit-sticky;
  position: sticky;
  right: 0px;
  background-color: #ffffff;
  background-clip: padding-box;
}

.Table--pinnedThreeDotMenuOptions .Table__TableRow > :last-child::before {
  pointer-events: none;
  content: '';
  position: absolute;
  right: 100%;
  bottom: 0;
  top: -1px;
  width: 16px;
  background-image: linear-gradient(to left, rgba(75, 75, 75, 0.1), rgba(255, 255, 255, 0) 100%);
  background-repeat: no-repeat;
}

.Table--pinnedThreeDotMenuOptions > :first-child > .Table__TableRow:first-child > :first-child::before {
  background-image: radial-gradient(ellipse farthest-side at bottom left, rgba(75, 75, 75, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

.Table--pinnedThreeDotMenuOptions > :last-child > .Table__TableRow:last-child > :first-child::before {
  background-image: radial-gradient(ellipse farthest-side at top left, rgba(75, 75, 75, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

.Table--pinnedThreeDotMenuOptions .Table__TableRow--selected > :first-child {
  background-color: #edf2fc;
}

.Table--pinnedThreeDotMenuOptions .Table__TableRow--hoverable:hover > :first-child {
  background-color: #f9eef2;
  box-shadow: inset 0 -2px 0 -1px #dde2e6;
}

/**
 * Pinned header
 */

.Table--pinnedHeader .Table__head .Table__TableHeader {
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #f4f4f4;
  background-clip: padding-box;
}

.Table--pinnedHeader .Table__head .Table__TableHeader::before {
  pointer-events: none;
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: -1px;
  height: 16px;
  background-image: linear-gradient(to bottom, rgba(75, 75, 75, 0.1), rgba(255, 255, 255, 0) 100%);
  background-repeat: no-repeat;
}

.ScrollWrapper:not(.ScrollWrapper--scrollTop) > .Table--pinnedHeader .Table__head .Table__TableHeader::before {
  background-image: none;
}

.Table--pinnedHeader .Table__head .Table__TableHeader:first-child::before {
  background-image: radial-gradient(ellipse farthest-side at top right, rgba(75, 75, 75, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

.Table--pinnedHeader .Table__head .Table__TableHeader:last-child::before,
.Table--pinnedHeader .Table__head .Table__TableHeader.Table__TableHeader--lastVisibleColumn::before {
  background-image: radial-gradient(ellipse farthest-side at top left, rgba(75, 75, 75, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  right: 0;
}

/**
 * If both the header and first column are pinned, the first cell can
 * only have a bottom (not right) shadow due to z-index collision.
 * The next row can inherit the right rounded shadow instead.
 */

.Table--pinnedFirstColumn.Table--pinnedHeader .Table__head .Table__TableHeader:first-child {
  z-index: 30;
}

.Table--pinnedFirstColumn.Table--pinnedHeader .Table__head .Table__TableHeader:first-child::before {
  width: auto;
  background-image: radial-gradient(ellipse farthest-side at top right, rgba(75, 75, 75, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

.ScrollWrapper:not(.ScrollWrapper--scrollTop) > .Table--pinnedFirstColumn.Table--pinnedHeader .Table__head .Table__TableHeader:first-child::before {
  background-image: none;
}

.ScrollWrapper:not(.ScrollWrapper--scrollLeft) > .Table--pinnedFirstColumn.Table--pinnedHeader .Table__head .Table__TableHeader:first-child::before {
  content: '';
}

.Table--pinnedFirstColumn.Table--pinnedHeader .Table__body > :first-child > :first-child::before {
  background-image: radial-gradient(ellipse farthest-side at bottom left, rgba(75, 75, 75, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

/**
 * Border workarounds
 *
 * Unfortunately, especially in Chrome/Edge, position sticky and table borders
 * have extremely buggy rendering behavior - they'll occasionally disappear or 'hang'
 * in place. These 'fake' borders (actually inset box shadows) help hide those bugs
 */

.Table--pinnedHeader .Table__head .Table__TableRow {
  border-color: transparent;
}

.Table--pinnedHeader .Table__head .Table__TableHeader::before {
  box-shadow: inset 0 2px 0 -1px #dde2e6;
}

.Table--pinnedHeader .Table__head .Table__TableHeader:not(:first-child):not(:last-child):not(.Table__TableHeader--lastVisibleColumn)::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
  width: 1px;
  background-color: #dde2e6;
  content: '';
}

.Table--pinnedFirstColumn .Table__TableRow:not(:last-child) > :first-child {
  box-shadow: inset 0 -1px 0 -1px #99abb4;
  border-bottom: 1px solid #dde2e6;
}
