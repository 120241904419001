.Thumbnail {
  width: 4rem;
  height: 4rem;
}

.Thumbnail--sm {
  width: 3rem;
  height: 3rem;
}

.Thumbnail--lg {
  width: 6rem;
  height: 6rem;
}
