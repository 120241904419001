.CardSection {
  border-top-width: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.CardSection--fullWidth {
  margin-left: -1rem;
  margin-right: -1rem;
}
