.DataTableHeading {
  position: relative;
  margin-bottom: 0.5rem;
}

.DataTableHeading .ControlStrip {
  border-width: 1px;
  border-color: #dde2e6;
  border-radius: 0.25rem;
}

.DataTableHeading .ControlStrip--actionButtons .Button--tertiary:first-child {
  padding-right: 0.5rem;
}

.DataTablePopover {
  width: 16rem;
}

.DataTablePopover .Popover__content {
  padding: 0;
}

.DataTablePopover .PopoverSeparator {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-left: 0;
  margin-right: 0;
}

.DataTableHeading__title {
  display: flex;
  align-items: center;
}

/**
 * Settings cog / popover toggle
 */

.DataTableHeading__config {
  position: absolute;
  left: -1.5rem;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  color: #7141d8;
}

.DataTableHeading__config:hover,
.DataTableHeading__config.focus-visible {
  color: #4e23ab;
}

.DataTableHeading__config .Icon {
  vertical-align: baseline;
}

/**
 * Popover
 */

.DataTableHeading__popover {
  min-width: 14rem;
}

.Popover.DataTablePopover--flyoutOpen {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/**
 * Flyout
 */

.PopoverAction.DataTableHeading__flyoutToggle {
  position: relative;
}

.PopoverAction.DataTableHeading__flyoutToggle::after {
  border-style: solid;
  border-color: transparent;
  border-width: 5px;
  border-left-width: 7px;
  border-left-color: currentColor;
  position: absolute;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  color: #7141d8;
  content: '';
}

.PopoverAction.DataTableHeading__flyoutToggle:hover,
.PopoverAction.DataTableHeading__flyoutToggle--open,
.PopoverActions__item:hover .PopoverAction.DataTableHeading__flyoutToggle {
  color: #ffffff;
  background-color: #7141d8;
}

.PopoverAction.DataTableHeading__flyoutToggle:hover::after,
.PopoverAction.DataTableHeading__flyoutToggle--open::after,
.PopoverActions__item:hover .PopoverAction.DataTableHeading__flyoutToggle::after {
  color: #ffffff;
}

.DataTableHeading__flyout {
  z-index: 40;
  visibility: hidden;
  opacity: 0;
  transition-property: opacity, visibility;
  transition-duration: 67ms;
  transition-timing-function: linear;
  position: relative;
  font-family: Noto Sans, sans-serif;
  border-width: 1px;
  border-radius: 0.25rem;
  box-shadow: 0 0.75rem 2.5rem -1rem rgba(0, 0, 0, 0.25);
  border-top-left-radius: 0;
  position: absolute;
  left: 100%;
  top: 0;
  margin-top: -1px;
  padding: 0.75rem;
  background-color: #ffffff;
  min-width: 14rem;
}

.DataTableHeading__flyout--visible,
.DataTableHeading__flyout:hover {
  visibility: visible;
  opacity: 1;
}

.DataTableHeading__flyout::before {
  content: '';
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  bottom: -0.75rem;
  left: -0.75rem;
}

/**
 * Show/Hide column checkboxes
 */

.DataTableHeading__flyout .Checkbox {
  display: flex;
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.DataTableHeading__flyout .Checkbox:last-of-type {
  margin-bottom: 1rem;
}
