.LoadingBar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 1rem;
}

/* prettier-ignore */

.LoadingBar--small {
  height: 0.5rem;
  width: 60%;
}

/**
 * Percentage text
 */

.LoadingBar__percent {
  font-family: Noto Sans, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  text-align: right;
  margin-left: 0.5rem;
  min-width: 2.6rem;
}

/**
 * Bar UI element
 */

.LoadingBar__background {
  flex-grow: 1;
  position: relative;
  height: 100%;
  border-radius: 0.5rem;
  background-color: #f4f4f4;
  overflow: hidden;
}

.LoadingBar__fill {
  position: absolute;
  height: 100%;
  background-color: #788f9c;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 1s;
  border-radius: inherit;
}

.LoadingBar__fill--red {
  background-image: repeating-linear-gradient(-60deg, #de1515, #de1515 25%, #e64f4f 25%, #e64f4f 50%, #de1515 50%);
  background-position: top left;
  background-size: 1.2rem 2.1rem;
  -webkit-animation: barberpole 1s linear infinite;
          animation: barberpole 1s linear infinite;
}

.LoadingBar__fill--green {
  background-image: repeating-linear-gradient(-60deg, #a8d763, #a8d763 25%, #c5e497 25%, #c5e497 50%, #a8d763 50%);
  background-position: top left;
  background-size: 1.2rem 2.1rem;
  -webkit-animation: barberpole 1s linear infinite;
          animation: barberpole 1s linear infinite;
}

.LoadingBar__fill--green--static {
  background-color: #a8d763;
}

.LoadingBar__fill--red--static {
  background-color: #d73955;
}

/**
 * Striped background mixin
 */

@-webkit-keyframes barberpole {
  0% {
    background-position: 4.2rem 1.2rem;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes barberpole {
  0% {
    background-position: 4.2rem 1.2rem;
  }

  100% {
    background-position: 0 0;
  }
}
