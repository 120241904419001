.CheckboxInput {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.CheckboxInput.focus-visible + .Checkbox {
  /* Non-webkit focus styles - FF, IE, etc. */
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .CheckboxInput.focus-visible + .Checkbox {
    /* Webkit native focus styles */
    outline: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.Checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: Noto Sans, sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  color: #596b74;
  cursor: pointer;
}

.Checkbox--block {
  display: flex;
}

.Checkbox--border {
  border-width: 1px;
  border-radius: 0.25rem;
  padding: 0.75rem;
  border-color: #788f9c;
}

.Checkbox__label {
  margin-left: 0.75rem;
}

.Checkbox__label--hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin-left: 0;
}

.Checkbox__label--asterisk::after {
  content: '*';
  color: #b21111;
  margin-left: 0.25rem;
}

.Checkbox__canvas {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
}

.Checkbox__background {
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 133ms;
  transition-property: fill;
  fill: currentColor;
  color: #ffffff;
}

.Checkbox__mark {
  fill: currentColor;
  color: #ffffff;
}

.Checkbox__border {
  fill: currentColor;
  color: #788f9c;
}

.Checkbox:hover .Checkbox__border {
  color: #6d98ed;
}

.Checkbox--checked,
.Checkbox--indeterminate {
  color: #596b74;
}

.Checkbox--checked .Checkbox__background,
.Checkbox--indeterminate .Checkbox__background,
.Checkbox--checked .Checkbox__border,
.Checkbox--indeterminate .Checkbox__border {
  fill: currentColor;
  color: #6d98ed;
}

.Checkbox--checked:hover .Checkbox__border,
.Checkbox--indeterminate:hover .Checkbox__border,
.Checkbox--checked:hover .Checkbox__background,
.Checkbox--indeterminate:hover .Checkbox__background {
  fill: currentColor;
  color: #7141d8;
}

.Checkbox--disabled {
  color: #bbc7cd;
  cursor: not-allowed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Checkbox--disabled .Checkbox__border {
  fill: currentColor;
  color: #dde2e6;
}

.Checkbox--disabled:hover .Checkbox__border {
  fill: currentColor;
  color: #dde2e6;
}

.Checkbox--disabled.Checkbox--checked .Checkbox__background,
.Checkbox--disabled.Checkbox--indeterminate .Checkbox__background {
  fill: currentColor;
  color: #f4f4f4;
}

.Checkbox--disabled.Checkbox--checked .Checkbox__mark,
.Checkbox--disabled.Checkbox--indeterminate .Checkbox__mark,
.Checkbox--disabled.Checkbox--checked .Checkbox__border,
.Checkbox--disabled.Checkbox--indeterminate .Checkbox__border {
  fill: currentColor;
  color: #99abb4;
}

.Checkbox__helperWrapper {
  display: flex;
  align-items: flex-start;
}

.Checkbox__helperWrapper:not(:empty) {
  margin-top: 0.25rem;
}

.Checkbox__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

/**
 * Validation & Errors
 */

.Checkbox--valid {
  border-color: #6d98ed;
}

.Checkbox__icon--valid {
  color: #8bca2f;
}
