@media (min-width: 768px) {
  .ConfirmationModal .Modal__content {
    min-width: 20rem;
    max-width: 45rem;
  }
}

.ConfirmationModal--footerFullWidth {
  padding-bottom: 0 !important;
  overflow-x: hidden !important;
}

.ConfirmationModal--hasBodyScroll {
  padding-right: 1rem !important;
}

.ConfirmationModal__footer--paddings {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.ConfirmationModal__Scroll--shadow {
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.5);
}

.ConfirmationModal__Icon--wrapper {
  border-radius: 9999px;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  position: relative;
  margin-bottom: 1.25rem;
  overflow: hidden;
}

.ConfirmationModal__Icon--background {
  border-radius: 9999px;
  position: absolute;
  z-index: 1;
  width: 3rem;
  height: 3rem;
  left: 0.5rem;
  top: 0.5rem;
  background-color: #123c90;
}

.ConfirmationModal__Icon {
  width: 4rem;
  height: 4rem;
  margin-bottom: 1.25rem;
  padding: 1rem;
  border-radius: 9999px;
}

.ConfirmationModal__Icon--default {
  padding: 0;
  color: #edf2fc;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ConfirmationModal__Icon--success {
  background-color: #e7f4d5;
  color: #689723;
}

.ConfirmationModal__Icon--error {
  background-color: #f9eef2;
  color: #b5253e;
}

.ConfirmationModal__body .Text:not(:empty) {
  margin-bottom: 0.25rem;
}

.ConfirmationModal__footer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
