.AdvancedFilterDrawer {
  max-width: 100vw;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.AdvancedFilterDrawer .AdvancedFilterForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.AdvancedFilterDrawer__Content {
  overflow-y: auto;
  height: 100%;
}

.AdvancedFilterDrawer__HeadingWrapper,
.AdvancedFilterDrawer__Body,
.AdvancedFilterDrawer__Footer {
  padding-right: 0.8125rem;
  padding-left: 0.625rem;
}

.AdvancedFilterDrawer__HeadingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  top: 0;
  border-bottom-width: 1px;
  border-color: #dde2e6;
  background-color: #ffffff;
  z-index: 2;
}

.AdvancedFilterDrawer__HeadingContainer {
  display: flex;
}

.AdvancedFilterDrawer__HeadingContainer .Icon {
  transform: rotate(90deg);
}

.AdvancedFilterDrawer__Heading {
  font-size: 1.5rem;
  font-weight: 300;
  margin-left: 0.75rem;
  margin-bottom: -1px;
}

.AdvancedFilterDrawer__ClearFilter {
  margin: 0;
  margin-right: 2.5rem;
  padding: 0;
  font-size: 1rem;
}

.AdvancedFilterDrawer__ClearFilter .Button__label {
  font-weight: 500;
}

.AdvancedFilterDrawer__Body {
  padding-bottom: 2rem;
}

.AdvancedFilterDrawer__Footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  padding-top: 1.0625rem;
  padding-bottom: 1.5625rem;
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff;
}

.AdvancedFilterDrawer__button {
  width: 100%;
}

@media (min-width: 768px) {
  .AdvancedFilterDrawer__HeadingWrapper,
  .AdvancedFilterDrawer__Body,
  .AdvancedFilterDrawer__Footer {
    padding-right: 2.25rem;
    padding-left: 6rem;
  }

  .AdvancedFilterDrawer__Footer {
    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
  }
}
