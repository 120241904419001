.SearchBar__element {
  transition-timing-function: cubic-bezier(0.45, 0, 1, 1);
  transition-duration: 67ms, 133ms;
  transition-property: all, background-color;
  display: flex;
  caret-color: #596b74;
  background-color: #ffffff;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: #788f9c;
  font-family: Noto Sans, sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  color: #3b474d;
  width: 100%;
  height: 2rem;
  overflow: hidden;
  box-sizing: border-box;
}

.SearchBar__element--small {
  height: 1.5rem;
  font-size: 0.75rem;
}

.SearchBar__element--large {
  height: 3rem;
}

.SearchBar__element::-webkit-input-placeholder {
  color: #788f9c;
}

.SearchBar__element::-moz-placeholder {
  color: #788f9c;
}

.SearchBar__element:-ms-input-placeholder {
  color: #788f9c;
}

.SearchBar__element::-ms-input-placeholder {
  color: #788f9c;
}

.SearchBar__element::placeholder {
  color: #788f9c;
}

.SearchBar__element:focus:not(.AdvancedSearchBar__input) {
  border-color: #6d98ed;
  outline: 0;
}

.SearchBar__element--disabled,
.SearchBar__element--disabled:focus {
  cursor: not-allowed;
  border-width: 1px;
  border-color: #dde2e6;
  color: #99abb4;
  background-color: #f4f4f4;
}

.SearchBar__element .TextInput__container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  height: 100%;
}

.SearchBar__element .TextInput__container--base {
  flex-grow: 1;
}

.SearchBar__element .TextInput__container--large {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.SearchBar__element .TextInput__prefixWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-color: #3b474d;
  border-right-width: 1px;
}

.SearchBar__element .TextInput__prefixWrapper--disabled {
  border-color: #bbc7cd;
}

.SearchBar__element .TextInput__prefix {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SearchBar__element .TextInput__prefix--small {
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.SearchBar__element .TextInput__prefix--large {
  min-width: 3rem;
  font-size: 2rem;
}

.SearchBar {
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-width: 8rem;
}

.SearchBar__element {
  padding-left: 2rem;
  padding-right: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.SearchBar__element--large {
  font-size: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

/**
 * Icons
 */

.SearchBar__wrapper {
  position: relative;
}

.SearchBar__icon {
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: #bbc7cd;
  pointer-events: none;
}

.SearchBar__icon--disabled {
  opacity: 0.5;
}

.SearchBar__icon--small {
  width: 0.75rem;
  height: 0.75rem;
}

.SearchBar__icon--large {
  width: 1.25rem;
  height: 1.25rem;
  left: 1rem;
}

.SearchBar__icon::-webkit-input-placeholder .SearchBar__icon--large {
  font-size: 0.75rem;
}

.SearchBar__icon::-moz-placeholder .SearchBar__icon--large {
  font-size: 0.75rem;
}

.SearchBar__icon:-ms-input-placeholder .SearchBar__icon--large {
  font-size: 0.75rem;
}

.SearchBar__icon::-ms-input-placeholder .SearchBar__icon--large {
  font-size: 0.75rem;
}

.SearchBar__icon::placeholder .SearchBar__icon--large {
  font-size: 0.75rem;
}

.SearchBar__icon__validation {
  flex-shrink: 0;
  margin-right: 0.5rem;
  position: relative;
  left: 0;
  top: 0;
  color: #8bca2f;
  transform: scale(1.2);
}

.SearchBar__clearButton {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  color: #bbc7cd;
  opacity: 0.5;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 133ms;
  transition-property: opacity;
}

.SearchBar__clearButton:hover,
.SearchBar__clearButton.focus-visible {
  opacity: 1;
}

.SearchBar__clearButton .Icon {
  pointer-events: none;
}

.SearchBar__clearButton--small .Icon {
  width: 0.75rem;
  height: 0.75rem;
}

.SearchBar__clearButton--large .Icon {
  width: 0.75rem;
  height: 0.75rem;
}

.SearchBar__helperWrapper {
  display: flex;
  align-items: flex-start;
}

.SearchBar__helperWrapper:not(:empty) {
  margin-top: 0.25rem;
}

.SearchBar__helperWrapper .SearchBar__icon--valid {
  color: #8bca2f;
}

/**
 * Validation & Errors
 */

.SearchBar__element--valid {
  border-color: #6d98ed;
}
