.TextLink {
  color: #7141d8;
  text-decoration: none;
  background-repeat: repeat-x;
  background-position: 0 90%;
  background-size: 1px 1px;
}

/**
 * Underline gradient mixin
 * @see https://css-tricks.com/styling-underlines-web/
 */

/**
 * States
 */

.TextLink:hover,
.TextLink.focus-visible {
  color: #4e23ab;
  background-image: linear-gradient(#c491cd, #c491cd);
}

.TextLink:active {
  color: #391a7c;
  background-image: linear-gradient(#a75ab4, #a75ab4);
}

/**
 * Icon alignment
 */

.TextLink__icon {
  vertical-align: baseline;
  position: relative;
  top: 0.1em;
  width: 0.9em;
  height: 0.9em;
  margin-right: 0.25rem;
}
