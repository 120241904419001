.SwitchInput {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.SwitchInput.focus-visible + .Switch {
  /* Non-webkit focus styles - FF, IE, etc. */
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .SwitchInput.focus-visible + .Switch {
    /* Webkit native focus styles */
    outline: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.Switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: Noto Sans, sans-serif;
  line-height: 1;
  font-size: 1rem;
  color: #596b74;
  cursor: pointer;
}

.Switch:hover .Switch__background {
  fill: currentColor;
  color: #596b74;
}

.Switch__label {
  margin-right: 1.5rem;
}

.Switch__label--hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin-right: 0;
}

.Switch__label--asterisk::after {
  content: '*';
  color: #d73955;
  margin-left: 0.25rem;
}

.Switch__canvas {
  flex-shrink: 0;
  width: 3rem;
  height: 1.5rem;
}

.Switch__background {
  fill: currentColor;
  color: #788f9c;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 67ms;
  transition-property: fill;
}

.Switch__toggler {
  fill: currentColor;
  color: #ffffff;
  transition-duration: 67ms;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.93, 0, 0.67, 1.21);
  transform: translate(4px, 3px);
}

.Switch__toggler--active {
  transform: translate(28px, 3px);
}

/**
 * Checked variant
 */

.Switch--checked .Switch__background {
  fill: currentColor;
  color: #6d98ed;
}

.Switch--checked:hover .Switch__background {
  fill: currentColor;
  color: #7141d8;
}

/**
 * Disabled variant
 */

.Switch--disabled {
  color: #bbc7cd;
  cursor: not-allowed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Switch--disabled .Switch__background {
  fill: currentColor;
  color: #dde2e6;
}

.Switch--disabled:hover .Switch__background {
  fill: currentColor;
  color: #dde2e6;
}

/**
 * Small variant
 */

.Switch--small .Switch__canvas {
  width: 2rem;
  height: 1rem;
}

.Switch__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

/**
 * Validation & Errors
 */

.Switch--valid {
  border-color: #6d98ed;
}

.Switch__icon--valid {
  color: #8bca2f;
}
