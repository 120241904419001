.Badge {
  position: absolute;
  bottom: 100%;
  left: 100%;
  transform: translate(-0.6rem, 0.6rem);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-family: Noto Sans, sans-serif;
  font-size: 0.75rem;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  color: #ffffff;
  background-color: #e64f4f;
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-radius: 9999px;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Badge--empty {
  padding: 0.5rem;
}
