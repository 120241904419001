.AdvancedSearchBar {
  display: flex;
  flex-direction: row;
  min-width: 12rem;
  max-width: 48rem;
  height: 3rem;
}

.AdvancedSearchBar--small {
  height: 1.5rem;
}

.AdvancedSearchBar:focus-within {
  box-shadow: 0 0 0 1px #6d98ed;
  border-radius: 0.25rem;
}

.AdvancedSearchBar__formWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #788f9c;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  overflow: hidden;
}

.AdvancedSearchBar__formWrapper--disabled {
  border-color: #dde2e6;
}

.AdvancedSearchBar__input {
  transition-timing-function: cubic-bezier(0.45, 0, 1, 1);
  transition-duration: 67ms, 133ms;
  transition-property: all, background-color;
  display: flex;
  caret-color: #596b74;
  background-color: #ffffff;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: #788f9c;
  font-family: Noto Sans, sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  color: #3b474d;
  width: 100%;
  height: 2rem;
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0;
  border-style: none;
  color: #596b74;
}

.AdvancedSearchBar__input--small {
  height: 1.5rem;
  font-size: 0.75rem;
}

.AdvancedSearchBar__input--large {
  height: 3rem;
}

.AdvancedSearchBar__input::-webkit-input-placeholder {
  color: #788f9c;
}

.AdvancedSearchBar__input::-moz-placeholder {
  color: #788f9c;
}

.AdvancedSearchBar__input:-ms-input-placeholder {
  color: #788f9c;
}

.AdvancedSearchBar__input::-ms-input-placeholder {
  color: #788f9c;
}

.AdvancedSearchBar__input::placeholder {
  color: #788f9c;
}

.AdvancedSearchBar__input:focus:not(.AdvancedSearchBar__input) {
  border-color: #6d98ed;
  outline: 0;
}

.AdvancedSearchBar__input--disabled,
.AdvancedSearchBar__input--disabled:focus {
  cursor: not-allowed;
  border-width: 1px;
  border-color: #dde2e6;
  color: #99abb4;
  background-color: #f4f4f4;
}

.AdvancedSearchBar__input .TextInput__container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  height: 100%;
}

.AdvancedSearchBar__input .TextInput__container--base {
  flex-grow: 1;
}

.AdvancedSearchBar__input .TextInput__container--large {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.AdvancedSearchBar__input .TextInput__prefixWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-color: #3b474d;
  border-right-width: 1px;
}

.AdvancedSearchBar__input .TextInput__prefixWrapper--disabled {
  border-color: #bbc7cd;
}

.AdvancedSearchBar__input .TextInput__prefix {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AdvancedSearchBar__input .TextInput__prefix--small {
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.AdvancedSearchBar__input .TextInput__prefix--large {
  min-width: 3rem;
  font-size: 2rem;
}

.AdvancedSearchBar__input:not(.AdvancedSearchBar__input--disabled)::-webkit-input-placeholder {
  color: #596b74;
}

.AdvancedSearchBar__input:not(.AdvancedSearchBar__input--disabled)::-moz-placeholder {
  color: #596b74;
}

.AdvancedSearchBar__input:not(.AdvancedSearchBar__input--disabled):-ms-input-placeholder {
  color: #596b74;
}

.AdvancedSearchBar__input:not(.AdvancedSearchBar__input--disabled)::-ms-input-placeholder {
  color: #596b74;
}

.AdvancedSearchBar__input:not(.AdvancedSearchBar__input--disabled)::placeholder {
  color: #596b74;
}

.AdvancedSearchBar__input--small {
  height: auto;
}

.AdvancedSearchBar__select {
  display: inline-block;
  height: 2rem;
  margin-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 2rem;
  line-height: 1.25;
  padding-top: 1px;
  font-family: Noto Sans, sans-serif;
  font-size: 1rem;
  border-width: 1px;
  border-color: #788f9c;
  border-radius: 0.25rem;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 24 12' fill='%237141d8'><polygon points='0,0 24,0 12,14'/></svg>");
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: right 0.55rem center;
  color: #596b74;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  height: 100%;
  border-color: #f4f4f4;
  border-radius: 0;
  max-width: 33%;
  display: none;
  background-color: #f0f0f0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-width: 0;
  border-right-width: 1px;
  border-color: #596b74;
}

.AdvancedSearchBar__select::-ms-expand {
  display: none;
}

@media (min-width: 768px) {
  .AdvancedSearchBar__select {
    display: block;
  }
}

.AdvancedSearchBar__select--disabled {
  color: #99abb4;
  border-color: #dde2e6;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 24 12' fill='%23dde2e6'><polygon points='0,0 24,0 12,14'/></svg>");
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: right 0.55rem center;
  background-color: #f4f4f4;
  cursor: not-allowed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-width: 0;
  border-left-width: 1px;
}

.AdvancedSearchBar__select--small {
  font-size: 0.75rem;
  height: 1.5rem;
  padding-right: 1.5rem;
  height: auto;
  margin-bottom: 0;
}

.AdvancedSearchBar__select--textWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.AdvancedSearchBar__select--textContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.AdvancedSearchBar__select--selectEl {
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.AdvancedSearchBar__button {
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.87rem;
  padding-right: 0.87rem;
}

.AdvancedSearchBar__button .Icon {
  width: 1.5rem;
  height: 1.5rem;
}

.AdvancedSearchBar__button--small {
  font-size: 0.625rem;
  line-height: 1.25;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  min-height: 1.5rem;
}

.AdvancedSearchBar__button--small .Icon {
  width: 0.75rem;
  height: 0.75rem;
}

.AdvancedSearchBar__icon--small {
  width: 0.75rem;
  margin-right: 0.25rem;
}
