.NestedRadio {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  min-height: 4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border-width: 1px;
  box-shadow: 0 0 0 1px #788f9c;
}

.NestedRadio--checked {
  box-shadow: 0 0 0 2px #7141d8;
}

.NestedRadio--tooltip .NestedRadio__label {
  margin-right: 3rem;
}

.NestedRadio--tooltip .NestedRadio__subLabel {
  margin-right: 2rem;
}

.NestedRadio__labelContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1.5rem;
}

.NestedRadio__labelContainer--hasSubLabel {
  padding-top: 0.25rem;
}

.NestedRadio__label {
  display: flex;
  align-items: center;
  color: #3b474d;
}

.NestedRadio__label span {
  /* Initial values */
  overflow: hidden;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 3rem;
}

.NestedRadioInput {
  cursor: pointer;
  flex-shrink: 0;
  align-self: flex-start;
  width: 1rem;
  height: 1rem;
  margin-top: 0.25rem;
  margin-right: 0.5rem;
}

.NestedRadioInput.focus-visible + .Radio {
  /* Non-webkit focus styles - FF, IE, etc. */
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .NestedRadioInput.focus-visible + .Radio {
    /* Webkit native focus styles */
    outline: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.NestedRadio__subLabel {
  font-size: 0.75rem;
  color: #596b74;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1.5rem;
  /* Initial values */
  overflow: hidden;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 3.375rem;
}

.NestedRadio__TooltipWrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.NestedRadio__TooltipIconWrapper {
  padding-right: 1.25rem;
  margin-top: -1px;
  height: 4rem;
  display: flex;
  align-items: center;
}

.NestedRadio__TooltipIcon {
  vertical-align: text-bottom;
  color: #7141d8;
  width: 1.25rem;
  height: 1.25rem;
}
