.Builder__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.Builder__row + .Builder__row {
  border-top-width: 1px;
  border-style: solid;
  border-color: #f4f4f4;
}

.Builder__actions {
  flex-shrink: 0;
  text-align: right;
  margin-left: 0.75rem;
}

.Builder__action {
  padding: 0.25rem;
  color: #7141d8;
  line-height: 0;
}

.Builder__action .Icon {
  pointer-events: none;
}

.Builder__action:hover,
.Builder__action.focus-visible {
  color: #4e23ab;
}

.Builder__action:active {
  color: #391a7c;
}

.Builder__action--disabled {
  cursor: not-allowed;
  color: #dde2e6;
}

.Builder__action--disabled:hover,
.Builder__action--disabled.focus-visible,
.Builder__action--disabled:active {
  color: #dde2e6;
}
