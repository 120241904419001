.Footer {
  color: #596b74;
  font-family: Noto Sans, sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  background-color: #ffffff;
  border-top: 2px solid;
  -o-border-image: radial-gradient(circle at -8% 77%, #4e23ab, #8a239c 49%, #c64876 100%);
     border-image: radial-gradient(circle at -8% 77%, #4e23ab, #8a239c 49%, #c64876 100%);
  border-image-slice: 1;
  min-height: 5rem;
}

.Footer__content {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.Footer__text {
  font-size: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  position: relative;
}

.Footer__text.Footer__text::before {
  display: block;
  position: absolute;
  border-color: #596b74;
  border-top-width: 1px;
  content: '';
  left: 0;
  top: 0;
  right: 0;
}

@media (min-width: 768px) {
  .Footer__text {
    margin-top: 1rem;
  }
}

.Footer__inner {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .Footer__inner {
    padding-bottom: 1rem;
    flex-direction: row;
  }
}
