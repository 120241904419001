.AdvancedSelectWrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  /**
   * AdvancedSelect & Label position variants
   */
}

.AdvancedSelectWrapper--row {
  flex-direction: row;
}

.AdvancedSelectWrapper--row .AdvancedSelect__label {
  margin-right: 0.5rem;
  align-self: center;
}

.AdvancedSelectWrapper--col {
  flex-direction: column;
  align-items: flex-start;
}

.AdvancedSelectWrapper--rowReverse .AdvancedSelect__label {
  order: 1;
  margin-left: 0.5rem;
  margin-bottom: 0;
  align-self: center;
}

.AdvancedSelectWrapper--colReverse {
  flex-direction: column-reverse;
  align-items: flex-start;
}

.AdvancedSelectWrapper--colReverse .AdvancedSelect__label {
  margin-top: 0.25rem;
}

.AdvancedSelectWrapper--small {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.AdvancedSelect--large .AdvancedSelect__control {
  height: 3rem;
  padding-left: 0.75rem;
  background-size: 1rem;
}

.AdvancedSelect--large .AdvancedSelect__control .AdvancedSelect__dropdown-indicator {
  padding-left: 3rem;
  background-size: 1rem;
}

/**
*  Styling react-select
*/

.AdvancedSelect-container {
  display: inline-block;
  background-color: #ffffff;
  font-family: Noto Sans, sans-serif;
}

.AdvancedSelect__control {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-width: 1px;
  border-color: #788f9c;
  border-radius: 0.25rem;
}

.AdvancedSelect__control .AdvancedSelect__value-container {
  padding-top: 0;
  padding-bottom: 0;
}

.AdvancedSelect__single-value,
.AdvancedSelect__input {
  font-family: Noto Sans, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #596b74;
}

.AdvancedSelect__value-container .AdvancedSelect__placeholder {
  color: #788f9c;
  display: contents;
}

.AdvancedSelect__menu-list .AdvancedSelect__option--is-focused {
  background-color: #dae5f9;
  color: #596b74;
}

.AdvancedSelect__menu-list .AdvancedSelect__option--is-selected {
  background-color: #6d98ed;
  color: #ffffff;
}

.AdvancedSelect__multi-value {
  display: inline-flex;
  align-items: center;
  height: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-family: Noto Sans, sans-serif;
  font-size: 0.75rem;
  color: #596b74;
  line-height: 1;
  border-radius: 9999px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-width: 1px;
  border-color: #596b74;
  border-color: #baccf3;
  color: #123c90;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.AdvancedSelect__multi-value:not(:last-child) {
  margin-right: 0.5rem;
}

.AdvancedSelect__multi-value.Tag--filled {
  background-color: #edf2fc;
  border-color: #edf2fc;
  color: #123c90;
}

.AdvancedSelect__multi-value__remove {
  order: -1;
  flex-shrink: 0;
  margin-right: 0.25rem;
  line-height: 1;
  cursor: pointer;
}

.AdvancedSelect__multi-value__remove > svg {
  width: 0.75rem;
  height: 0.75rem;
}

.AdvancedSelect__option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.AdvancedSelect__group .AdvancedSelect__group-heading {
  font-weight: 500;
  color: #dde2e6;
}

.AdvancedSelect__dropdown-indicator {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 24 12' fill='%237141d8'><polygon points='0,0 24,0 12,14'/></svg>");
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: right 0.55rem center;
  cursor: pointer;
}

.AdvancedSelect__dropdown-indicator > svg {
  display: none;
}

.AdvancedSelect__clear-indicator {
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
}

.AdvancedSelect__clear-indicator > svg {
  width: 0.75rem;
  height: 0.75rem;
}

/**
 * Size variants
 */

.AdvancedSelect--small * {
  font-size: 0.75rem;
  align-content: center;
}

.AdvancedSelect--small .AdvancedSelect__multi-value {
  height: 1.25rem;
}

.AdvancedSelect--small .AdvancedSelect__multi-value__remove > svg {
  height: 0.75rem;
  width: 0.75rem;
}

.AdvancedSelect--large .AdvancedSelect__multi-value {
  height: 2rem;
}

.AdvancedSelect--large .AdvancedSelect__multi-value__remove > svg {
  height: 1.5rem;
  width: 1.5rem;
}

/**
 * Disabled variant
 */

.AdvancedSelect-container.AdvancedSelect--disabled {
  pointer-events: auto;
}

.AdvancedSelect-container.AdvancedSelect--disabled .AdvancedSelect__control--is-disabled {
  color: #99abb4;
  border-color: #dde2e6;
  background-color: #f4f4f4;
  cursor: not-allowed;
}

.AdvancedSelect-container.AdvancedSelect--disabled .AdvancedSelect__control--is-disabled .AdvancedSelect__dropdown-indicator {
  cursor: not-allowed;
}

.AdvancedSelect-container.AdvancedSelect--disabled .AdvancedSelect__control--is-disabled .AdvancedSelect__indicator {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 24 12' fill='%23dde2e6'><polygon points='0,0 24,0 12,14'/></svg>");
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: right 0.55rem center;
}

.AdvancedSelect__helperWrapper {
  display: flex;
  align-items: flex-start;
}

.AdvancedSelect__helperWrapper:not(:empty) {
  margin-top: 0.25rem;
}

.AdvancedSelect__icon {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

/**
 * Validation & Errors
 */

.AdvancedSelect__icon--valid {
  color: #8bca2f;
}

.AdvancedSelect__icon--error {
  color: #b21111;
}
