.CoachMark {
  position: relative;
  border-radius: 0.125rem;
  background-color: #ffffff;
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  min-width: 14rem;
  max-width: 24rem;
}

.CoachMark--title {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.CoachMark--title,
.CoachMark--content,
.CoachMark--progress {
  font-size: 0.75rem;
}

.CoachMark--checkbox {
  padding-top: 0.5rem;
  font-size: 0.875rem;
}

.CoachMark--title,
.CoachMark--content,
.CoachMark--checkbox {
  color: #3b474d;
}

.CoachMark--progress {
  color: #596b74;
}

.CoachMark--footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.75rem;
}

.CoachMark--skip {
  text-decoration: underline;
  color: #596b74;
}

.CoachMark--buttons {
  margin-left: auto;
}
