.ScrollWrapper {
  width: 100%;
}

.ScrollWrapper--horizontal {
  overflow-x: auto;
}

.ScrollWrapper--vertical {
  overflow-y: auto;
}

.ScrollWrapper--both {
  overflow: auto;
}

.ScrollWrapper--bg-white {
  background-color: #ffffff;
}

/**
 * Shadow gradient mixins
 */

.ScrollWrapper--scrollTop {
  background-image: radial-gradient(100% 2.5rem at top, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollRight {
  background-image: radial-gradient(2.5rem 100% at right, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollBottom {
  background-image: radial-gradient(100% 2.5rem at bottom, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollLeft {
  background-image: radial-gradient(2.5rem 100% at left, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollTop.ScrollWrapper--scrollRight {
  background-image: radial-gradient(100% 2.5rem at top, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(2.5rem 100% at right, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollTop.ScrollWrapper--scrollBottom {
  background-image: radial-gradient(100% 2.5rem at top, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(100% 2.5rem at bottom, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollTop.ScrollWrapper--scrollLeft {
  background-image: radial-gradient(100% 2.5rem at top, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(2.5rem 100% at left, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollRight.ScrollWrapper--scrollBottom {
  background-image: radial-gradient(2.5rem 100% at right, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(100% 2.5rem at bottom, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollRight.ScrollWrapper--scrollLeft {
  background-image: radial-gradient(2.5rem 100% at right, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(2.5rem 100% at left, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollBottom.ScrollWrapper--scrollLeft {
  background-image: radial-gradient(100% 2.5rem at bottom, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(2.5rem 100% at left, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollTop.ScrollWrapper--scrollRight.ScrollWrapper--scrollBottom {
  background-image: radial-gradient(100% 2.5rem at top, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(2.5rem 100% at right, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(100% 2.5rem at bottom, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollTop.ScrollWrapper--scrollRight.ScrollWrapper--scrollLeft {
  background-image: radial-gradient(100% 2.5rem at top, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(2.5rem 100% at right, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(2.5rem 100% at left, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollTop.ScrollWrapper--scrollBottom.ScrollWrapper--scrollLeft {
  background-image: radial-gradient(100% 2.5rem at top, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(100% 2.5rem at bottom, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(2.5rem 100% at left, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollRight.ScrollWrapper--scrollBottom.ScrollWrapper--scrollLeft {
  background-image: radial-gradient(2.5rem 100% at right, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(100% 2.5rem at bottom, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(2.5rem 100% at left, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}

.ScrollWrapper--scrollTop.ScrollWrapper--scrollRight.ScrollWrapper--scrollBottom.ScrollWrapper--scrollLeft {
  background-image: radial-gradient(100% 2.5rem at top, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(2.5rem 100% at right, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(100% 2.5rem at bottom, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%), radial-gradient(2.5rem 100% at left, rgba(75, 75, 75, 0.1) 10%, rgba(255, 255, 255, 0) 53%);
}
