.Tag {
  display: inline-flex;
  align-items: center;
  height: 1.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-family: Noto Sans, sans-serif;
  font-size: 0.75rem;
  color: #596b74;
  line-height: 1;
  border-radius: 9999px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-width: 1px;
  border-color: #596b74;
}

.Tag:not(:last-child) {
  margin-right: 0.5rem;
}

.Tag__dismiss {
  order: -1;
  flex-shrink: 0;
  margin-right: 0.25rem;
  line-height: 1;
  cursor: pointer;
}

.Tag__dismissIcon {
  width: 0.75rem;
  height: 0.75rem;
}

.Tag__content {
  margin-left: 1px;
  margin-right: 1px;
  padding-left: 1px;
  padding-right: 1px;
}

.Tag--success {
  border-color: #c5e497;
  color: #456418;
}

.Tag--success.Tag--filled {
  background-color: #e7f4d5;
  border-color: #e7f4d5;
  color: #456418;
}

.Tag--info {
  border-color: #baccf3;
  color: #123c90;
}

.Tag--info.Tag--filled {
  background-color: #edf2fc;
  border-color: #edf2fc;
  color: #123c90;
}

.Tag--warning {
  border-color: #ffcf80;
  color: #985f00;
}

.Tag--warning.Tag--filled {
  background-color: #ffebcb;
  border-color: #ffebcb;
  color: #ce8000;
}

.Tag--danger {
  border-color: #ee8a8a;
  color: #b21111;
}

.Tag--danger.Tag--filled {
  background-color: #fcecec;
  border-color: #fcecec;
  color: #b21111;
}

.Tag--secondary {
  border-color: #b8a0eb;
  color: #391a7c;
}

.Tag--secondary.Tag--filled {
  background-color: #f5f2fc;
  border-color: #f5f2fc;
  color: #4e23ab;
}
