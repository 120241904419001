input[type='file'] {
  display: none;
}

.FileUpload {
  position: relative;
  width: 31.25rem;
  font-family: Noto Sans, sans-serif;
}

.FileUpload__area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 13.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.05em;
  border-width: 2px;
  border-style: dashed;
}

/**
 * Make the filepond hitbox extend over our text above
 */

.filepond--wrapper {
  z-index: 10;
  position: relative;
  width: 100%;
}

.filepond--drop-label label {
  display: block;
  padding-top: 13.5rem;
  cursor: pointer;
}

/**
 * Hide filepond list style
 */

.filepond--list-scroller {
  display: none;
}

/**
 * Icon upload status
 */

.FileUpload__icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  border-radius: 9999px;
  color: #ffffff;
  background-color: #dde2e6;
  transition-property: background-color;
  transition-duration: 133ms;
}

.FileUpload__icon--active {
  background-color: #6d98ed;
}

.FileUpload__icon .Icon {
  width: 2rem;
  height: 2rem;
  margin-top: -0.25rem;
}
