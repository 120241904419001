.ModalHeader {
  width: 100%;
  text-align: center;
  flex-shrink: 0;
  margin-top: auto;
}

.ModalHeader__Icon--wrapper {
  background-color: #edf2fc;
  padding: 0.75rem;
  display: inline-block;
  border-radius: 9999px;
  margin-bottom: 1.25rem;
}

.ModalHeader__Icon--default {
  background-color: #edf2fc;
  color: #123c90;
  width: 2.5rem;
  height: 2.5rem;
}

@media (min-width: 768px) {
  .ModalHeader {
    margin-top: 0;
    margin-bottom: auto;
  }
}

.ModalHeader .ModalHeader--hasChildren {
  padding-top: 0.5rem;
}
