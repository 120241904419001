/**
 * DataTable column indicator
 * Used for both dragging and resizing indicators
 */

.DataTable .Table__TableData,
.DataTable .Table__TableHeader {
  position: relative;
}

.DataTable__columnIndicator {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: -1px;
  right: -1px;
  width: 3px;
}

.Table__TableData:last-child .DataTable__columnIndicator,
.Table__TableHeader:last-child .DataTable__columnIndicator {
  right: 0;
}

.Table__TableHeader[scope='row'] .DataTable__columnIndicator,
.Table__TableHeader:first-child .DataTable__columnIndicator {
  right: 0;
  width: 4px;
}

.Table__head .Table__TableHeader .DataTable__columnIndicator {
  bottom: -2px;
}

/**
 * DataTable resizer
 */

.DataTable__resizer {
  cursor: col-resize;
  background-color: #7141d8;
  opacity: 0;
}

.DataTable__resizer--hover {
  opacity: 1;
}

.DataTable__resizer--active {
  background-color: #6d98ed;
  opacity: 1;
}

.DataTable__resizer::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.25rem;
  right: -0.25rem;
  content: '';
}

.Table__TableData:last-child .DataTable__resizer::after,
.Table__TableHeader:last-child .DataTable__resizer::after {
  right: 0;
}
