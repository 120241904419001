.Thumbnail {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  text-align: center;
}

.Thumbnail__icon {
  flex-basis: 2rem;
  height: 2rem;
}

.Thumbnail--image {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 6.25rem;
  height: 4.375rem;
}

.Thumbnail--image img {
  margin-left: auto;
  margin-right: auto;
  max-width: 6.25rem;
  max-height: 4.375rem;
}
