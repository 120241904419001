.Notifications {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Notifications__Header {
  display: flex;
  align-items: center;
}

.Notifications__Heading {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 0.75rem;
  padding-left: 1rem;
}

@media (min-width: 768px) {
  .Notifications__Heading {
    padding-left: 2rem;
  }
}

.Notifications__Body {
  overflow-y: auto;
  height: 100%;
  padding-bottom: 4rem;
}

.Notifications .NotificationItem {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Notifications__Header,
.Notifications .NotificationItem {
  border-bottom-width: 1px;
  border-color: #f4f4f4;
}

.Notifications__options {
  color: #7141d8;
}
