.MobileCard {
  border-width: 1px;
  margin-bottom: 1rem;
  color: #3b474d;
  background-color: #ffffff;
  padding: 0.75rem 0.5rem 0.75rem 1.25rem;
}

.MobileCard .MobileCardKey {
  font-family: Noto Sans, sans-serif;
  text-align: left;
  vertical-align: top;
  font-weight: 300;
  max-width: 20rem;
  padding: 0.3rem 1rem 0.3rem 0;
  font-size: 0.875rem;
}

.MobileCard .MobileCardValue {
  font-family: Noto Sans, sans-serif;
  text-align: left;
  vertical-align: top;
  font-size: 0.875rem;
  padding: 0.3rem 0.5rem 0.3rem 0;
  font-weight: normal;
}

.MobileCard .ThreeDotMenu .Icon--dots-horizontal-triple {
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
}
