.NotificationItem {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding: 0.5rem;
  cursor: default;
  font-family: Noto Sans, sans-serif;
  font-size: 1rem;
  text-align: left;
  color: #788f9c;
}

.NotificationItem__UnreadDot {
  border-radius: 9999px;
  height: 0.5rem;
  width: 0.5rem;
  flex-shrink: 0;
  background-color: #6d98ed;
  visibility: hidden;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .NotificationItem__UnreadDot {
    margin-left: 0.5rem;
  }
}

.NotificationItem__Icon {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
  align-self: flex-start;
}

.NotificationItem__Title {
  margin-right: 0.5rem;
  font-weight: 600;
}

.NotificationItem__Timestamp {
  font-size: 0.75rem;
}

.NotificationItem--isUnread {
  color: #596b74;
  cursor: pointer;
}

.NotificationItem--isUnread .NotificationItem__UnreadDot {
  visibility: visible;
}

.NotificationItem--isUnread .NotificationItem__Icon {
  color: #6d98ed;
}

.NotificationItem--isUnread .NotificationItem__Title {
  font-weight: 700;
}

.NotificationItem:hover {
  background-color: #edf2fc;
}

.NotificationItem__Body {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  max-height: 6rem;
}

.NotificationItem__Body--show-more {
  max-height: none;
  -webkit-line-clamp: unset;
}

.NotificationItem__ShowMore {
  display: flex;
  align-items: center;
  color: #7141d8;
}

.NotificationItem__ShowMore__Icon {
  width: 1rem;
  height: 1rem;
}
