.ThreeDotMenu {
  display: inline;
  position: relative;
}

.ThreeDotMenu__trigger {
  color: #7141d8;
}

.ThreeDotMenu__trigger:hover,
.ThreeDotMenu__trigger.focus-visible {
  color: #4e23ab;
}

.ThreeDotMenu__trigger:active {
  color: #391a7c;
}
