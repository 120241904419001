.PopoverAction {
  display: block;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: Noto Sans, sans-serif;
  font-size: 1rem;
  text-align: left;
  line-height: 1;
  color: #596b74;
  text-decoration: none;
  border-radius: 0.25rem;
  position: relative;
}

.PopoverAction--disabled {
  color: #99abb4;
  cursor: not-allowed;
}

.PopoverAction--loading .LoadingSpinner {
  position: absolute;
  left: 0.25rem;
}

.PopoverAction:hover:not(.PopoverAction--disabled) {
  color: #ffffff;
  background-color: #7141d8;
}

/**
 * Active circle indicator
 */

.PopoverAction__icon {
  display: inline-block;
  width: 1rem;
  margin-right: 0.5rem;
}

.PopoverAction__icon,
.PopoverAction .Icon {
  vertical-align: top;
}

.PopoverAction--active {
  position: relative;
}

.PopoverAction--active::before {
  position: absolute;
  left: 0.5rem;
  top: 50%;
  margin-top: -0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 9999px;
  background-color: #6d98ed;
  content: '';
}

.PopoverAction--active:hover::before {
  background-color: #ffffff;
}
