.NavbarActions {
  display: flex;
}

.NavbarActions__item {
  position: relative;
  display: flex;
  align-items: center;
  color: #3b474d;
  font-family: Noto Sans, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1;
  border-right-width: 1px;
  border-color: #bbc7cd;
  /**
    * NOTE: a consumer provides action component, so we add padding to their container.
    * Maybe a better way would be to expose NavAction component.
   **/
}

.NavbarActions__item > * {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.NavbarActions__item:first-child {
  border-left-width: 1px;
}

.NavbarActions__item:last-child {
  border-right-width: 0;
}

.NavbarActions__item:hover {
  cursor: pointer;
}

.NavbarActions__item:before {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -1px;
  height: 1px;
  background-color: transparent;
  content: '';
}

@media (min-width: 1280px) {
  .NavbarActions__item:last-child {
    border-right-width: 1px;
  }
}

.NavbarActions__item--active:before {
  background-color: #ffffff;
}

.NavbarActions__item--active .NavbarActions__itemIcon {
  transform: rotate(180deg);
}

.NavbarActions__itemIcon {
  color: #596b74;
  transition-property: transform;
  transition-duration: 200ms;
}
