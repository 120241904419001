.PromotionalBannerImage__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 24.625rem;
  height: 13.75rem;
}

.PromotionalBannerImage__content > div {
  box-shadow: none;
}

@media (min-width: 768px) {
  .PromotionalBannerImage__wrapper {
    height: 13.75rem;
    width: 100%;
    max-width: 20.75rem;
  }
}

@media (min-width: 1024px) {
  .PromotionalBannerImage__wrapper {
    min-width: 25.75rem;
    width: 25.75rem;
  }
}
