.Blanket {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
}

.Blanket--underHeader {
  z-index: 30;
}

.Blanket--overflowHidden {
  overflow: hidden;
}

.Blanket__Overlay {
  transition-property: background-color;
  transition-timing-function: ease-out;
  transition-duration: 200ms;
  will-change: background-color;
  background-color: rgba(32, 32, 32, 0);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Blanket--leave.Blanket--leaveActive .Blanket__Overlay {
  background-color: rgba(32, 32, 32, 0);
}

.Blanket--isMounted .Blanket__Overlay,
.Blanket--enter.Blanket--enterActive .Blanket__Overlay {
  background-color: rgba(32, 32, 32, 0.5);
}
