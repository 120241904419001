.MultiSelectBrickGroup {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.MultiSelectBrickGroup .MultiSelectBrick {
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
