.Popover {
  z-index: 40;
  visibility: hidden;
  opacity: 0;
  transition-property: opacity, visibility;
  transition-duration: 67ms;
  transition-timing-function: linear;
  position: relative;
  font-family: Noto Sans, sans-serif;
  background-color: #ffffff;
  border-width: 1px;
  border-radius: 0.25rem;
  box-shadow: 0 0.75rem 2.5rem -1rem rgba(0, 0, 0, 0.25);
}

.Popover--visible {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.Popover--top,
.Popover--top-left,
.Popover--top-right,
.Popover--center {
  bottom: 0.5rem;
}

.Popover--bottom,
.Popover--bottom-left,
.Popover--bottom-right {
  top: 0.5rem;
}

.Popover--right {
  left: 0.5rem;
}

.Popover--left {
  right: 0.5rem;
}

/**
 * Arrow / direction / anchor
 */

.Popover__arrow {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
  border-top-width: 1px;
  border-left-width: 1px;
}

.Popover__arrow--top,
.Popover__arrow--top-left,
.Popover__arrow--top-right,
.Popover__arrow--center {
  top: 100%;
  margin-top: -0.5rem;
  transform: rotate(-135deg);
}

.Popover__arrow--bottom,
.Popover__arrow--bottom-left,
.Popover__arrow--bottom-right {
  bottom: 100%;
  margin-bottom: -0.5rem;
  transform: rotate(45deg);
}

.Popover__arrow--top-left,
.Popover__arrow--bottom-left {
  left: 1.5rem;
}

.Popover__arrow--top-right,
.Popover__arrow--bottom-right {
  left: calc(100% - 1rem - 1.5rem);
}

.Popover__arrow--top,
.Popover__arrow--bottom,
.Popover__arrow--center {
  left: calc(50% - 1rem / 2);
}

.Popover__arrow--right,
.Popover__arrow--left {
  top: calc(50% - 1rem / 2);
}

.Popover__arrow--right {
  right: 100%;
  margin-right: -0.5rem;
  transform: rotate(-45deg);
}

.Popover__arrow--left {
  left: 100%;
  margin-left: -0.5rem;
  transform: rotate(135deg);
}

/**
 * Content - make sure it overlaps the arrow
 */

.Popover__content {
  position: relative;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 0.25rem;
}
