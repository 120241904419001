.CheckboxInput {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.CheckboxInput.focus-visible + .Checkbox {
  /* Non-webkit focus styles - FF, IE, etc. */
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .CheckboxInput.focus-visible + .Checkbox {
    /* Webkit native focus styles */
    outline: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.CheckboxInput + .MultiSelectBrick--disabled {
  color: #bbc7cd;
  border-color: #dde2e6;
  cursor: not-allowed;
}

.MultiSelectBrick {
  position: relative;
  display: inline-flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Noto Sans, sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  color: #7141d8;
  background-color: #ffffff;
  cursor: pointer;
  border-width: 1px;
  border-color: #7141d8;
  border-radius: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.MultiSelectBrick--block {
  display: flex;
}

.MultiSelectBrick--checked {
  color: #ffffff;
  background-color: #7141d8;
}
