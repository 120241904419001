.AdvancedRadioButtonWrapper {
  /**
 * Checked variant
 */
  /**
 * Disabled variant
 */
}

.AdvancedRadioButtonWrapper .AdvancedRadioButtonInput {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.AdvancedRadioButtonWrapper .AdvancedRadioButtonInput.focus-visible + .AdvancedRadioButton {
  /* Non-webkit focus styles - FF, IE, etc. */
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .AdvancedRadioButtonWrapper .AdvancedRadioButtonInput.focus-visible + .AdvancedRadioButton {
    /* Webkit native focus styles */
    outline: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton {
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 67ms;
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: Noto Sans, sans-serif;
  line-height: 1.5;
  font-size: 1rem;
  color: #596b74;
  cursor: pointer;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton__radio {
  margin-top: 0.25rem;
  align-self: flex-start;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton__helperText {
  font-size: 0.75rem;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton__canvas {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton__background {
  fill: currentColor;
  color: #ffffff;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton__circle {
  transition-timing-function: cubic-bezier(0.45, 0, 1, 1);
  transition-duration: 133ms;
  transition-property: fill;
  fill: currentColor;
  color: #ffffff;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton__border {
  fill: currentColor;
  color: #788f9c;
  transition-timing-function: cubic-bezier(0, 0, 0.55, 1);
  transition-duration: 200ms;
  transition-property: fill;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton:hover .AdvancedRadioButton__border {
  color: #6d98ed;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton--checked {
  color: #596b74;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton--checked .AdvancedRadioButton__circle,
.AdvancedRadioButtonWrapper .AdvancedRadioButton--checked .AdvancedRadioButton__border {
  fill: currentColor;
  fill: #8bca2f;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton--checked .AdvancedRadioButton__icon {
  fill: #8bca2f;
  transform: scale(1.2);
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton--checked:hover .AdvancedRadioButton__border,
.AdvancedRadioButtonWrapper .AdvancedRadioButton--checked:hover .AdvancedRadioButton__circle {
  fill: currentColor;
  fill: #8bca2f;
}

.AdvancedRadioButtonWrapper .LoadingSpinner__background--sm,
.AdvancedRadioButtonWrapper .LoadingSpinner__fill--sm {
  stroke-width: 0.6rem;
}

.AdvancedRadioButtonWrapper .LoadingSpinner {
  margin-top: 0.25rem;
  align-self: flex-start;
}

.AdvancedRadioButtonWrapper .LoadingSpinner__svg--sm,
.AdvancedRadioButtonWrapper .LoadingSpinner--sm {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton--disabled {
  color: #bbc7cd;
  cursor: not-allowed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton--disabled .AdvancedRadioButton__border {
  fill: currentColor;
  color: #dde2e6;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton--disabled:hover .AdvancedRadioButton__border {
  fill: currentColor;
  color: #dde2e6;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton--disabled.AdvancedRadioButton--checked .AdvancedRadioButton__background {
  fill: currentColor;
}

.AdvancedRadioButtonWrapper .AdvancedRadioButton--disabled.AdvancedRadioButton--checked .AdvancedRadioButton__circle,
.AdvancedRadioButtonWrapper .AdvancedRadioButton--disabled.AdvancedRadioButton--checked .AdvancedRadioButton__border {
  fill: currentColor;
  color: #99abb4;
}
