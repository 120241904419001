.BackTo {
  height: 2.5rem;
  position: relative;
  pointer-events: none;
}

@media (min-width: 1280px) {
  .BackTo {
    height: 3.5rem;
  }
}

.BackTo__link {
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Noto Sans, sans-serif;
  line-height: 1;
  font-size: 0.75rem;
  color: #7141d8;
  pointer-events: auto;
}

@media (min-width: 768px) {
  .BackTo__link {
    font-size: 1rem;
  }
}

.BackTo__link:hover {
  color: #4e23ab;
}

.BackTo__link .Icon {
  margin-right: 0.5rem;
}
