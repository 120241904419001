body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.underline {
	text-decoration: underline;
}

p {
	margin-bottom: 1rem;
}

.buttonLink {
	color: unset !important;
}

.buttonLinkWithoutIcon {
	color: unset !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.LoadingSpinner--lg {
	height: 6rem !important;
	width: 6rem !important;
}

.Switch--checked .Switch__background {
	color: #7141d8;
}

.Switch--disabled .Switch__background {
	color: #bbc7cd;
}

.link-disabled {
	cursor: not-allowed;
	pointer-events: none;
}

.Footer__text {
	font-size: 12px;
}

.Tooltip,
.Tooltip__arrow {
	background-color: #7141d8;
}

.Tooltip {
	font-size: 12px;
	margin-left: 10px;
}

.icon-tooltip:hover {
	cursor: pointer;
}

.Popover,
.Popover__arrow,
.Popover__content {
	background-color: #7141d8;
	border-color: #7141d8;
	color: white;
	font-size: 14px;
}

.popover .PopoverAction:hover:not(.popover .PopoverAction--disabled),
.popover-715 .PopoverAction:hover:not(.popover .PopoverAction--disabled),
.popover-250 .PopoverAction:hover:not(.popover-250 .PopoverAction--disabled) {
	background-color: #ffffff;
	color: #596b74;
}

.popover {
	width: 600px;
	z-index: 9999;
	background-color: #ffffff;
	border-color: #ffffff;
}

.popover-715 {
	width: 715px;
	height: 400px;
	z-index: 9999;
	background-color: #ffffff;
	border-color: #ffffff;
}

.popover-250 {
	width: 250px;
	z-index: 9999;
	background-color: #ffffff;
	border-color: #ffffff;
	margin-top: 20px;
}

.popover .Popover__arrow,
.popover-715 .Popover__arrow,
.popover .Popover__content,
.popover-715 .Popover__content,
.popover-250 .Popover__content {
	background-color: #ffffff;
	border-color: #ffffff;
	color: #596b74;
}

.popover-250 .Popover__arrow {
	background-color: #7141d8;
	border-color: #7141d8;
}

.popover .Popover__content,
.popover-715 .Popover__content,
.popover-250 .Popover__content {
	border-top: 10px solid #7141d8;
	border-radius: 0.35rem;
	padding: 20px;
	text-align: justify;
}

.popover-715 .Popover__content {
	border-top: 10px solid #7141d8;
	border-radius: 0.35rem;
	padding: 10px;
	text-align: justify;
	& li {
		margin-left: 25px;
	}
}

/*color: #596b74;*/

.popover-actions button {
	font-size: 14px;
	text-decoration: underline;
	color: white;
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}

.popover-actions-grey button {
	font-size: 14px;
	text-decoration: underline;
	color: #596b74;
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}

.listOrdered {
	list-style: decimal;
	margin-left: 50px;
	color: inherit;
}

.Drawer {
	overflow-y: auto;
}

/*.Text--strong {*/
/*	font-weight: 600 !important;*/
/*}*/

.Footer__inner {
	padding-bottom: 0;
	margin-top: 1rem;
}
