.PopoverActions {
  width: auto;
  margin: 0;
}

.PopoverActions__item {
  display: block;
}

.PopoverActions__item:not(:first-child) {
  margin-top: -0.25rem;
}
