.LinkButton {
	min-height: 2rem;
	border-radius: 0.25rem;
	border-color: transparent;
	background-color: #7141d8;
	padding: 0.5rem 1.5rem;
	font-family: Noto Sans, sans-serif;
	font-weight: 500;
	font-size: 1rem;
	color: white;
}

body {
	background-color: #f4f4f4;
}

.Main {
	/*min-width: 10rem !important; */
	max-width: 45rem !important;
}

.Header + .Main {
	/*margin-top: 4.75rem !important*/
}
