.NavLinks {
  align-items: stretch;
  display: none;
}

@media (min-width: 1024px) {
  .NavLinks {
    display: flex;
  }
}

.NavLinks__item {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.NavLinks__item:hover .NavLinks__chevron {
  transform: rotate(180deg);
}

.NavLinks__item:before {
  position: absolute;
  height: 1px;
  background-color: transparent;
  z-index: 10;
  left: 1px;
  right: 1px;
  bottom: -1px;
  content: ' ';
}

.NavLinks__item:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 1px;
  border-bottom-width: 0;
  border-color: transparent;
  top: 0.25rem;
  content: ' ';
}

.NavLinks__item--hover .SubNavLinks {
  visibility: visible;
  opacity: 1;
}

.NavLinks__item--hover .NavLinks__chevron {
  transform: rotate(180deg);
}

.NavLinks__item--hover.NavLinks__item--hasSubNav:after {
  border-color: #bbc7cd;
}

.NavLinks__item--hover.NavLinks__item--hasSubNav:before {
  background-color: #ffffff;
}

.NavLinks__linkWrapper {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 1;
}

@media (min-width: 1280px) {
  .NavLinks .NavLinks__item:first-of-type .NavLinks__linkWrapper {
    padding-left: 0;
  }
}

.NavLinks__link {
  /* stylelint-disable */
  padding-top: 10px;
  padding-bottom: 10px;
  /* stylelint-enable */
  color: #3b474d;
  font-family: Noto Sans, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
}

.NavLinks__link:hover {
  color: #4e23ab;
}

.NavLinks__link--navless {
  cursor: default;
}

.NavLinks__link--navless:hover {
  color: #3b474d;
}

/* stylelint-disable */

.NavLinks__link--active,
.NavLinks__link.active {
  box-shadow: 0 6px 0 0 #7141d8;
}

/* stylelint-enable */

.NavLinks__linkIcon {
  margin-left: 0.25rem;
  color: #596b74;
}

.NavLinks__linkIcon--newWindow {
  margin-left: 0.5rem;
  color: #4e23ab;
  padding-bottom: 1px;
  height: 0.75rem;
  width: 0.75rem;
}

.NavLinks__chevron {
  transition-property: transform;
  transition-duration: 200ms;
}

.NavLinks__item:hover .SubNavLinks,
.NavLinks__item .SubNavLinks:hover {
  visibility: visible;
  opacity: 1;
}

.NavLinks__item--hasSubNav:hover:before {
  background-color: #ffffff;
}

.NavLinks__item--hasSubNav:hover:after {
  border-color: #bbc7cd;
}

.SubNavLinks {
  position: absolute;
  left: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  visibility: hidden;
  flex-direction: column;
  background-color: #ffffff;
  border-width: 1px;
  border-color: #bbc7cd;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition-property: opacity;
  transition-duration: 133ms;
  opacity: 0;
  top: 100%;
  min-width: 16rem;
}

.SubNavLinks__link {
  /* stylelint-disable */
  padding-top: 10px;
  padding-bottom: 10px;
  /* stylelint-enable */
  color: #3b474d;
  font-family: Noto Sans, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  border-width: 0;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.SubNavLinks__link:hover {
  color: #4e23ab;
}

.SubNavLinks__link--active:before,
.SubNavLinks__link.active:before {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #7141d8;
  border-radius: 9999px;
  content: ' ';
  left: 0;
  top: 0.75rem;
}
