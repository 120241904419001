.DataTable .Table__foot .Table__TableFooter {
  background-color: #ffffff;
  height: 3.5rem;
}

.DataTable .Table__foot .Table__TableFooter .Table__TableData,
.DataTable .Table__foot .Table__TableFooter .Table__TableHeader {
  font-weight: 600;
  vertical-align: middle;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.DataTable.Table .Table__TableFooter--condensed {
  height: auto;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.DataTable.Table .Table__TableFooter--condensed .Table__TableHeader {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
