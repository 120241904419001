.UploadStatus__list {
  display: flex;
  flex-direction: column-reverse;
}

.UploadStatus {
  margin-top: 0.5rem;
  border-width: 2px;
  display: flex;
  align-items: center;
  padding: 2rem;
  position: relative;
}

.UploadStatus__closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.UploadStatus__closeButton .Icon {
  height: 1rem;
  width: 1rem;
  pointer-events: none;
  color: #bbc7cd;
}

.UploadStatus__fileName {
  font-weight: 600;
  color: #3b474d;
  display: flex;
  align-items: center;
  min-width: 0;
}

.UploadStatus__fileName .UploadStatus__fileName--base {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.UploadStatus__fileName .UploadStatus__fileName--icon {
  flex-shrink: 0;
  display: flex;
}

.UploadStatus__fileSize,
.UploadStatus__errorCaption {
  font-size: 0.75rem;
  color: #3b474d;
}

.UploadStatus__errorList {
  margin-top: 0.5rem;
}

.UploadStatus__errorList .ListItem::before,
.UploadStatus__errorList .ListItem {
  color: #b21111;
}

.UploadStatus__icon {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
}

.UploadStatus__icon--success {
  color: #8bca2f;
}

.UploadStatus__icon--error {
  color: #b21111;
}

.UploadStatus__LoadingBar {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.UploadStatus__file {
  display: grid;
  flex-grow: 1;
  padding-left: 1rem;
  padding-right: 1rem;
}

.filepond--assistant {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
