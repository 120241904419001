.DataTable .Table__body .Table__TableRow {
  height: 3.5rem;
}

.DataTable .Table__body .Table__TableRow .Table__TableData {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.DataTable .Table__body .Table__TableRow:first-of-type {
  box-shadow: inset 0 0.8rem 0.5rem -0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
}

/**
 * Condensed
 */

.DataTable.Table .Table__TableRow--condensed {
  height: auto;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.DataTable.Table .Table__TableRow--condensed .Table__TableHeader {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
