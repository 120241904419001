.PanelWizardContainer {
  width: 100%;
}

@media (min-width: 768px) {
  .PanelWizardContainer {
    max-width: 200rem;
  }
}

.PanelWizard {
  border-width: 1px;
  background-color: #ffffff;
  border-radius: 0.25rem;
  top: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .PanelWizard {
    position: -webkit-sticky;
    position: sticky;
  }
}

.PanelWizard__LoadingContainer {
  padding: 1rem;
  padding-top: 1.25rem;
  padding-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .PanelWizard__LoadingContainer {
    padding: 2.25rem;
    padding-bottom: 2rem;
  }
}

.PanelWizard__StepsContainer {
  padding: 1rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .PanelWizard__StepsContainer {
    padding: 2.25rem;
  }
}

.PanelWizard__Separator {
  width: 100%;
  border-top-width: 1px;
}

.PanelWizard__LoadingBar {
  margin-bottom: 0.25rem;
}

.PanelWizard__Percentage {
  font-weight: 300;
  font-size: 0.75rem;
}

.PanelWizard__Step {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}

.PanelWizard__Step:last-child {
  margin: 0;
}

.PanelWizard__Step .Text {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.PanelWizard__Checkmark,
.PanelWizard__EmptyCheck {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
}

.PanelWizard__Checkmark {
  color: #a8d763;
}

.PanelWizard__EmptyCheck {
  border-radius: 9999px;
  border-width: 1px;
}
