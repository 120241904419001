.DataTable__headerButton {
  line-height: 1;
  text-align: center;
  color: #788f9c;
}

.DataTable__headerButton:hover,
.DataTable__headerButton.focus-visible,
.DataTable__headerButton--active {
  color: #6d98ed;
}

/**
 * Config popover
 */

.DataTable__headerPopover {
  min-width: 8rem;
}

/**
 * Config/dropdown arrow
 */

.DataTable__headerButton--arrow {
  margin-left: 0.25rem;
  width: 1rem;
  height: 1rem;
}

.DataTable__headerButton--arrow::before {
  border-style: solid;
  border-color: transparent;
  border-width: 5px;
  border-top-width: 7px;
  border-top-color: currentColor;
  display: inline-block;
  vertical-align: text-bottom;
  content: '';
}

.DataTable__headerButton--arrowDescending {
  transform: rotate(180deg);
}

/**
 * Drag anchor
 * @see DataTableDragger.less for styling
 */

.DataTable__headerButton--drag {
  margin-right: 0.25rem;
}

.DataTable__headerButton--drag:hover {
  color: #7141d8;
}

.DataTable .Table__head .Table__TableRow {
  background-color: #ffffff;
  height: 4rem;
}

.DataTable .Table__head .Table__TableRow .Table__TableHeader {
  font-weight: 600;
  text-align: left;
  vertical-align: middle;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.DataTable__TableHeaderDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/**
 * Condensed
 */

.DataTable.Table .Table__TableRow--condensed {
  height: auto;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.DataTable.Table .Table__TableRow--condensed .Table__TableHeader {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.DataTable.Table--pinnedThreeDotMenuOptions .Table__head .Table__TableHeader--lastVisibleColumn {
  width: 0.5rem;
  z-index: -10;
  padding: 0 1.25rem;
}

.DataTable.Table--pinnedThreeDotMenuOptions .Table__head .Table__TableHeader--lastVisibleColumn .DataTable__TableHeaderDiv .DataTable__headerButton {
  display: none;
}

.DataTable .Table__body .Table__TableData.Table__TableData--lastVisibleColumn {
  padding-left: 1rem;
  padding-right: 1rem;
}
