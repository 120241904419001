.InputLabel {
  display: block;
  margin-bottom: 0.25rem;
  font-family: Noto Sans, sans-serif;
  line-height: 1.25;
  font-size: 1rem;
  color: #3b474d;
}

.InputLabel--hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.InputLabel__asterisk::after {
  content: '*';
  color: #b21111;
  margin-left: 0.25rem;
}

.InputLabel--wrapper {
  display: flex;
}

.InputLabel--wrapper .TooltipWrapper > div {
  display: flex;
}

.InputLabel__Tooltip {
  width: auto;
  display: inline-block;
}

.InputLabel__Icon {
  color: #7141d8;
  cursor: pointer;
  height: 1.25rem;
  width: auto;
}

.InputLabel__Tooltip_wrapper {
  bottom: 0.75rem;
}

.InputLabel__Tooltip_block {
  margin-left: 0.25rem;
}
