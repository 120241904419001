/* stylelint-disable declaration-block-no-duplicate-properties */

.Truncated {
  min-width: 0;
  /* Important for long words! */
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  /* Important for long words! */
}
