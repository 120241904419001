.PageSection {
  background-color: #ffffff;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 1280px) {
  .PageSection {
    padding: 1.5rem;
  }
}
