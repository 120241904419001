.TextLink:hover {
	background-image: none !important;
}

.status-text {
	font-size: 14px !important;
	font-weight: 500 !important;
}

.order-tracking-field-info {
	margin-top: -8px;
}

.tracking-field-select-drawer {
	margin-top: 0 !important;
	margin-left: 14px;
}
